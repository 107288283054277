import React from 'react';
import { Auth0Provider } from '@auth0/auth0-react';
import { OneApiProvider } from './ApiContext';
import { OneProvider } from './OneContext';
import { ThemeProvider } from 'styled-components';
import {
  StyledEngineProvider,
  ThemeProvider as MuiThemeProvider,
} from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';
import maTheme from '../theme';
import { getConfig } from '../config';
import { AuthorizationProvider } from './AuthorizationContext';
import history from '../utils/history';
import { QueryClientProvider } from '@tanstack/react-query';
import queryClient from '../services/queryClient';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import AuthTokenProvider from './AuthTokenContext';

const onRedirectCallback = (appState) => {
  history.push(
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  );
};

// create a new context to make the theme selection configurable - not required for now
// https://medium.com/@rossbulat/react-dark-mode-with-styled-theming-and-context-57557de6400
const currentTheme = 0;

export const ContextProviders = ({ children }) => {
  return (
    <Auth0Provider
      domain={getConfig('REACT_APP_AUTH0_DOMAIN')}
      clientId={getConfig('REACT_APP_AUTH0_CLIENT_ID')}
      redirectUri={window.location.origin}
      audience={getConfig('REACT_APP_AUTH0_AUDIENCE')}
      onRedirectCallback={onRedirectCallback}
    >
      <AuthTokenProvider>
        <QueryClientProvider client={queryClient}>
          <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
          <OneApiProvider>
            <StyledEngineProvider injectFirst>
              <MuiThemeProvider theme={maTheme[currentTheme]}>
                <ThemeProvider theme={maTheme[currentTheme]}>
                  <SnackbarProvider maxSnack={3}>{children}</SnackbarProvider>
                </ThemeProvider>
              </MuiThemeProvider>
            </StyledEngineProvider>
          </OneApiProvider>
        </QueryClientProvider>
      </AuthTokenProvider>
    </Auth0Provider>
  );
};

export const ProtectedContextProviders = ({ children }) => {
  return (
    <OneProvider>
      <AuthorizationProvider>{children}</AuthorizationProvider>
    </OneProvider>
  );
};
