export const projectTypes = {
  DUE_DILIGENCE: { key: 'DUE_DILIGENCE', title: 'Due Diligence' },
  SANITY_CHECK: { key: 'SANITY_CHECK', title: 'Sanity Check' },
};

export const projectStates = {
  ACTIVE: { key: 'ACTIVE', title: 'Active' },
  COMPLETED: { key: 'COMPLETED', title: 'Completed' },
  OBSOLETE: { key: 'OBSOLETE', title: 'Obsolete' },
};
