import React, { FC, ReactNode, useEffect, useState } from 'react';
import Sidebar from '../../components/layout/sidebar';
import Header from '../../components/layout/Header';
import Footer from '../../components/layout/Footer';
import { CssBaseline, useMediaQuery } from '@mui/material';
import {
  AppContent,
  Drawer,
  DRAWER_WIDTH,
  MainContent,
  Root,
} from './DashboardLayout.style';
import CollapseButton from '../../components/layout/sidebar/CollapseButton';
import TMLogo from '../../components/layout/TMLogo';
import { DashboardLayoutGlobalStyle } from '../globalStyles';

type TDashboardLayoutProps = { children: ReactNode; routes: any };
const DashboardLayout: FC<TDashboardLayoutProps> = ({ children, routes }) => {
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('md'));
  const isSmallDisplay = useMediaQuery((theme: any) =>
    theme.breakpoints.down('xl')
  );

  const [isSidebarOpen, setIsSidebarOpen] = useState(!isSmallDisplay);
  useEffect(() => {
    setIsSidebarOpen(!isSmallDisplay);
  }, [isSmallDisplay]);

  const toggleSidebar = () => {
    setIsSidebarOpen((prevState) => !prevState);
  };

  return (
    <Root>
      <CssBaseline />
      <DashboardLayoutGlobalStyle />
      {!isMobile && <TMLogo />}
      <Drawer>
        <Sidebar
          routes={routes}
          PaperProps={isMobile ? { style: { width: DRAWER_WIDTH } } : {}}
          variant={isMobile ? 'temporary' : 'permanent'}
          open={isSidebarOpen}
          onClose={toggleSidebar}
        />
        {!isMobile && (
          <CollapseButton isOpen={isSidebarOpen} onToggle={toggleSidebar} />
        )}
      </Drawer>
      <AppContent isSidebarOpen={isSidebarOpen}>
        <Header onDrawerToggle={toggleSidebar} />
        <MainContent sx={{ p: { xs: 5, lg: 10 } }}>{children}</MainContent>
        <Footer />
      </AppContent>
    </Root>
  );
};

export default DashboardLayout;
