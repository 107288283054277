/* eslint-disable @typescript-eslint/ban-types */
/**
 * Generated by @openapi-codegen
 *
 * @version 0.1.0
 */
import * as reactQuery from '@tanstack/react-query';
import { useOneApiContext, OneApiContext } from './oneApiContext';
import type * as Fetcher from './oneApiFetcher';
import { oneApiFetch } from './oneApiFetcher';
import type * as Schemas from './oneApiSchemas';
import type * as RequestBodies from './oneApiRequestBodies';

export type AssessmentQuestionCreateError = Fetcher.ErrorWrapper<undefined>;

export type AssessmentQuestionCreateVariables = {
  body: RequestBodies.AssessmentQuestion;
} & OneApiContext['fetcherOptions'];

export const fetchAssessmentQuestionCreate = (
  variables: AssessmentQuestionCreateVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    Schemas.AssessmentQuestion,
    AssessmentQuestionCreateError,
    RequestBodies.AssessmentQuestion,
    {},
    {},
    {}
  >({ url: '/assessment-question', method: 'post', ...variables, signal });

export const useAssessmentQuestionCreate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.AssessmentQuestion,
      AssessmentQuestionCreateError,
      AssessmentQuestionCreateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useOneApiContext();
  return reactQuery.useMutation<
    Schemas.AssessmentQuestion,
    AssessmentQuestionCreateError,
    AssessmentQuestionCreateVariables
  >(
    (variables: AssessmentQuestionCreateVariables) =>
      fetchAssessmentQuestionCreate({ ...fetcherOptions, ...variables }),
    options
  );
};

export type AssessmentQuestionReadPathParams = {
  /**
   * A UUID string identifying this assessment question.
   *
   * @format uuid
   */
  id: string;
};

export type AssessmentQuestionReadError = Fetcher.ErrorWrapper<undefined>;

export type AssessmentQuestionReadVariables = {
  pathParams: AssessmentQuestionReadPathParams;
} & OneApiContext['fetcherOptions'];

export const fetchAssessmentQuestionRead = (
  variables: AssessmentQuestionReadVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    Schemas.AssessmentQuestion,
    AssessmentQuestionReadError,
    undefined,
    {},
    {},
    AssessmentQuestionReadPathParams
  >({ url: '/assessment-question/{id}', method: 'get', ...variables, signal });

export const useAssessmentQuestionRead = <TData = Schemas.AssessmentQuestion>(
  variables: AssessmentQuestionReadVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.AssessmentQuestion,
      AssessmentQuestionReadError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<
    Schemas.AssessmentQuestion,
    AssessmentQuestionReadError,
    TData
  >(
    queryKeyFn({
      path: '/assessment-question/{id}',
      operationId: 'assessmentQuestionRead',
      variables,
    }),
    ({ signal }) =>
      fetchAssessmentQuestionRead({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type AssessmentQuestionUpdatePathParams = {
  /**
   * A UUID string identifying this assessment question.
   *
   * @format uuid
   */
  id: string;
};

export type AssessmentQuestionUpdateError = Fetcher.ErrorWrapper<undefined>;

export type AssessmentQuestionUpdateVariables = {
  body: RequestBodies.AssessmentQuestion;
  pathParams: AssessmentQuestionUpdatePathParams;
} & OneApiContext['fetcherOptions'];

export const fetchAssessmentQuestionUpdate = (
  variables: AssessmentQuestionUpdateVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    Schemas.AssessmentQuestion,
    AssessmentQuestionUpdateError,
    RequestBodies.AssessmentQuestion,
    {},
    {},
    AssessmentQuestionUpdatePathParams
  >({ url: '/assessment-question/{id}', method: 'put', ...variables, signal });

export const useAssessmentQuestionUpdate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.AssessmentQuestion,
      AssessmentQuestionUpdateError,
      AssessmentQuestionUpdateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useOneApiContext();
  return reactQuery.useMutation<
    Schemas.AssessmentQuestion,
    AssessmentQuestionUpdateError,
    AssessmentQuestionUpdateVariables
  >(
    (variables: AssessmentQuestionUpdateVariables) =>
      fetchAssessmentQuestionUpdate({ ...fetcherOptions, ...variables }),
    options
  );
};

export type CollaborationPermissionCreateError =
  Fetcher.ErrorWrapper<undefined>;

export type CollaborationPermissionCreateRequestBody = {
  /**
   * channelId as base64 json object
   */
  channelId: {
    projectId?: void;
    /**
     * 'Project' | 'Finding' | 'MaturityRating' | 'FindingTopic' | 'FindingArea'
     */
    entity?: string;
    id?: void;
    /**
     * entity field
     */
    field?: string;
  };
  /**
   * Valid JWT of the end user.
   */
  endUserToken: string;
};

export type CollaborationPermissionCreateVariables = {
  body: CollaborationPermissionCreateRequestBody;
} & OneApiContext['fetcherOptions'];

/**
 * Validates if token can access to given channelId
 */
export const fetchCollaborationPermissionCreate = (
  variables: CollaborationPermissionCreateVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    undefined,
    CollaborationPermissionCreateError,
    CollaborationPermissionCreateRequestBody,
    {},
    {},
    {}
  >({ url: '/collaboration/permission', method: 'post', ...variables, signal });

/**
 * Validates if token can access to given channelId
 */
export const useCollaborationPermissionCreate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      CollaborationPermissionCreateError,
      CollaborationPermissionCreateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useOneApiContext();
  return reactQuery.useMutation<
    undefined,
    CollaborationPermissionCreateError,
    CollaborationPermissionCreateVariables
  >(
    (variables: CollaborationPermissionCreateVariables) =>
      fetchCollaborationPermissionCreate({ ...fetcherOptions, ...variables }),
    options
  );
};

export type CollaborationPersistentCreateError =
  Fetcher.ErrorWrapper<undefined>;

export type CollaborationPersistentCreateVariables = {
  body: RequestBodies.CollaborationPersistentCreateData;
} & OneApiContext['fetcherOptions'];

/**
 * Saves field of provide object in channelId.
 */
export const fetchCollaborationPersistentCreate = (
  variables: CollaborationPersistentCreateVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    undefined,
    CollaborationPersistentCreateError,
    RequestBodies.CollaborationPersistentCreateData,
    {},
    {},
    {}
  >({ url: '/collaboration/persistent', method: 'post', ...variables, signal });

/**
 * Saves field of provide object in channelId.
 */
export const useCollaborationPersistentCreate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      CollaborationPersistentCreateError,
      CollaborationPersistentCreateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useOneApiContext();
  return reactQuery.useMutation<
    undefined,
    CollaborationPersistentCreateError,
    CollaborationPersistentCreateVariables
  >(
    (variables: CollaborationPersistentCreateVariables) =>
      fetchCollaborationPersistentCreate({ ...fetcherOptions, ...variables }),
    options
  );
};

export type CollaborationRetrieveCreateError = Fetcher.ErrorWrapper<undefined>;

export type CollaborationRetrieveCreateVariables = {
  body: RequestBodies.CollaborationPersistentCreateData;
} & OneApiContext['fetcherOptions'];

/**
 * Returns field of provide object in channelId.
 */
export const fetchCollaborationRetrieveCreate = (
  variables: CollaborationRetrieveCreateVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    undefined,
    CollaborationRetrieveCreateError,
    RequestBodies.CollaborationPersistentCreateData,
    {},
    {},
    {}
  >({ url: '/collaboration/retrieve', method: 'post', ...variables, signal });

/**
 * Returns field of provide object in channelId.
 */
export const useCollaborationRetrieveCreate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      CollaborationRetrieveCreateError,
      CollaborationRetrieveCreateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useOneApiContext();
  return reactQuery.useMutation<
    undefined,
    CollaborationRetrieveCreateError,
    CollaborationRetrieveCreateVariables
  >(
    (variables: CollaborationRetrieveCreateVariables) =>
      fetchCollaborationRetrieveCreate({ ...fetcherOptions, ...variables }),
    options
  );
};

export type CommentsListError = Fetcher.ErrorWrapper<undefined>;

export type CommentsListResponse = Schemas.Comment[];

export type CommentsListVariables = OneApiContext['fetcherOptions'];

export const fetchCommentsList = (
  variables: CommentsListVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<CommentsListResponse, CommentsListError, undefined, {}, {}, {}>({
    url: '/comments',
    method: 'get',
    ...variables,
    signal,
  });

export const useCommentsList = <TData = CommentsListResponse>(
  variables: CommentsListVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<CommentsListResponse, CommentsListError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<CommentsListResponse, CommentsListError, TData>(
    queryKeyFn({ path: '/comments', operationId: 'commentsList', variables }),
    ({ signal }) =>
      fetchCommentsList({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type CommentsCreateError = Fetcher.ErrorWrapper<undefined>;

export type CommentsCreateVariables = {
  body: RequestBodies.Comment;
} & OneApiContext['fetcherOptions'];

export const fetchCommentsCreate = (
  variables: CommentsCreateVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    Schemas.Comment,
    CommentsCreateError,
    RequestBodies.Comment,
    {},
    {},
    {}
  >({ url: '/comments', method: 'post', ...variables, signal });

export const useCommentsCreate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.Comment,
      CommentsCreateError,
      CommentsCreateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useOneApiContext();
  return reactQuery.useMutation<
    Schemas.Comment,
    CommentsCreateError,
    CommentsCreateVariables
  >(
    (variables: CommentsCreateVariables) =>
      fetchCommentsCreate({ ...fetcherOptions, ...variables }),
    options
  );
};

export type CommentsReadPathParams = {
  /**
   * A UUID string identifying this comment.
   *
   * @format uuid
   */
  id: string;
};

export type CommentsReadError = Fetcher.ErrorWrapper<undefined>;

export type CommentsReadVariables = {
  pathParams: CommentsReadPathParams;
} & OneApiContext['fetcherOptions'];

export const fetchCommentsRead = (
  variables: CommentsReadVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    Schemas.Comment,
    CommentsReadError,
    undefined,
    {},
    {},
    CommentsReadPathParams
  >({ url: '/comments/{id}', method: 'get', ...variables, signal });

export const useCommentsRead = <TData = Schemas.Comment>(
  variables: CommentsReadVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.Comment, CommentsReadError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<Schemas.Comment, CommentsReadError, TData>(
    queryKeyFn({
      path: '/comments/{id}',
      operationId: 'commentsRead',
      variables,
    }),
    ({ signal }) =>
      fetchCommentsRead({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type CommentsUpdatePathParams = {
  /**
   * A UUID string identifying this comment.
   *
   * @format uuid
   */
  id: string;
};

export type CommentsUpdateError = Fetcher.ErrorWrapper<undefined>;

export type CommentsUpdateVariables = {
  body: RequestBodies.Comment;
  pathParams: CommentsUpdatePathParams;
} & OneApiContext['fetcherOptions'];

export const fetchCommentsUpdate = (
  variables: CommentsUpdateVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    Schemas.Comment,
    CommentsUpdateError,
    RequestBodies.Comment,
    {},
    {},
    CommentsUpdatePathParams
  >({ url: '/comments/{id}', method: 'put', ...variables, signal });

export const useCommentsUpdate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.Comment,
      CommentsUpdateError,
      CommentsUpdateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useOneApiContext();
  return reactQuery.useMutation<
    Schemas.Comment,
    CommentsUpdateError,
    CommentsUpdateVariables
  >(
    (variables: CommentsUpdateVariables) =>
      fetchCommentsUpdate({ ...fetcherOptions, ...variables }),
    options
  );
};

export type FindingAreaFindingTopicsListPathParams = {
  id: string;
};

export type FindingAreaFindingTopicsListError = Fetcher.ErrorWrapper<undefined>;

export type FindingAreaFindingTopicsListResponse =
  Schemas.MaturityFindingTopic[];

export type FindingAreaFindingTopicsListVariables = {
  pathParams: FindingAreaFindingTopicsListPathParams;
} & OneApiContext['fetcherOptions'];

export const fetchFindingAreaFindingTopicsList = (
  variables: FindingAreaFindingTopicsListVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    FindingAreaFindingTopicsListResponse,
    FindingAreaFindingTopicsListError,
    undefined,
    {},
    {},
    FindingAreaFindingTopicsListPathParams
  >({
    url: '/finding-area/{id}/finding-topics',
    method: 'get',
    ...variables,
    signal,
  });

export const useFindingAreaFindingTopicsList = <
  TData = FindingAreaFindingTopicsListResponse
>(
  variables: FindingAreaFindingTopicsListVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      FindingAreaFindingTopicsListResponse,
      FindingAreaFindingTopicsListError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<
    FindingAreaFindingTopicsListResponse,
    FindingAreaFindingTopicsListError,
    TData
  >(
    queryKeyFn({
      path: '/finding-area/{id}/finding-topics',
      operationId: 'findingAreaFindingTopicsList',
      variables,
    }),
    ({ signal }) =>
      fetchFindingAreaFindingTopicsList(
        { ...fetcherOptions, ...variables },
        signal
      ),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type FindingAreaRatingListPathParams = {
  id: string;
};

export type FindingAreaRatingListError = Fetcher.ErrorWrapper<undefined>;

export type FindingAreaRatingListVariables = {
  pathParams: FindingAreaRatingListPathParams;
} & OneApiContext['fetcherOptions'];

export const fetchFindingAreaRatingList = (
  variables: FindingAreaRatingListVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    undefined,
    FindingAreaRatingListError,
    undefined,
    {},
    {},
    FindingAreaRatingListPathParams
  >({ url: '/finding-area/{id}/rating', method: 'get', ...variables, signal });

export const useFindingAreaRatingList = <TData = undefined>(
  variables: FindingAreaRatingListVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<undefined, FindingAreaRatingListError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<undefined, FindingAreaRatingListError, TData>(
    queryKeyFn({
      path: '/finding-area/{id}/rating',
      operationId: 'findingAreaRatingList',
      variables,
    }),
    ({ signal }) =>
      fetchFindingAreaRatingList({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type FindingAreasCreateError = Fetcher.ErrorWrapper<undefined>;

export type FindingAreasCreateVariables = {
  body: RequestBodies.FindingArea;
} & OneApiContext['fetcherOptions'];

export const fetchFindingAreasCreate = (
  variables: FindingAreasCreateVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    Schemas.FindingArea,
    FindingAreasCreateError,
    RequestBodies.FindingArea,
    {},
    {},
    {}
  >({ url: '/finding-areas/', method: 'post', ...variables, signal });

export const useFindingAreasCreate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.FindingArea,
      FindingAreasCreateError,
      FindingAreasCreateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useOneApiContext();
  return reactQuery.useMutation<
    Schemas.FindingArea,
    FindingAreasCreateError,
    FindingAreasCreateVariables
  >(
    (variables: FindingAreasCreateVariables) =>
      fetchFindingAreasCreate({ ...fetcherOptions, ...variables }),
    options
  );
};

export type FindingAreasReadPathParams = {
  /**
   * A UUID string identifying this finding area.
   *
   * @format uuid
   */
  id: string;
};

export type FindingAreasReadError = Fetcher.ErrorWrapper<undefined>;

export type FindingAreasReadVariables = {
  pathParams: FindingAreasReadPathParams;
} & OneApiContext['fetcherOptions'];

export const fetchFindingAreasRead = (
  variables: FindingAreasReadVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    Schemas.FindingArea,
    FindingAreasReadError,
    undefined,
    {},
    {},
    FindingAreasReadPathParams
  >({ url: '/finding-areas/{id}', method: 'get', ...variables, signal });

export const useFindingAreasRead = <TData = Schemas.FindingArea>(
  variables: FindingAreasReadVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.FindingArea,
      FindingAreasReadError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<Schemas.FindingArea, FindingAreasReadError, TData>(
    queryKeyFn({
      path: '/finding-areas/{id}',
      operationId: 'findingAreasRead',
      variables,
    }),
    ({ signal }) =>
      fetchFindingAreasRead({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type FindingAreasUpdatePathParams = {
  /**
   * A UUID string identifying this finding area.
   *
   * @format uuid
   */
  id: string;
};

export type FindingAreasUpdateError = Fetcher.ErrorWrapper<undefined>;

export type FindingAreasUpdateVariables = {
  body: RequestBodies.FindingArea;
  pathParams: FindingAreasUpdatePathParams;
} & OneApiContext['fetcherOptions'];

export const fetchFindingAreasUpdate = (
  variables: FindingAreasUpdateVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    Schemas.FindingArea,
    FindingAreasUpdateError,
    RequestBodies.FindingArea,
    {},
    {},
    FindingAreasUpdatePathParams
  >({ url: '/finding-areas/{id}', method: 'put', ...variables, signal });

export const useFindingAreasUpdate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.FindingArea,
      FindingAreasUpdateError,
      FindingAreasUpdateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useOneApiContext();
  return reactQuery.useMutation<
    Schemas.FindingArea,
    FindingAreasUpdateError,
    FindingAreasUpdateVariables
  >(
    (variables: FindingAreasUpdateVariables) =>
      fetchFindingAreasUpdate({ ...fetcherOptions, ...variables }),
    options
  );
};

export type FindingAreasDeletePathParams = {
  /**
   * A UUID string identifying this finding area.
   *
   * @format uuid
   */
  id: string;
};

export type FindingAreasDeleteError = Fetcher.ErrorWrapper<undefined>;

export type FindingAreasDeleteVariables = {
  pathParams: FindingAreasDeletePathParams;
} & OneApiContext['fetcherOptions'];

export const fetchFindingAreasDelete = (
  variables: FindingAreasDeleteVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    undefined,
    FindingAreasDeleteError,
    undefined,
    {},
    {},
    FindingAreasDeletePathParams
  >({ url: '/finding-areas/{id}', method: 'delete', ...variables, signal });

export const useFindingAreasDelete = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      FindingAreasDeleteError,
      FindingAreasDeleteVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useOneApiContext();
  return reactQuery.useMutation<
    undefined,
    FindingAreasDeleteError,
    FindingAreasDeleteVariables
  >(
    (variables: FindingAreasDeleteVariables) =>
      fetchFindingAreasDelete({ ...fetcherOptions, ...variables }),
    options
  );
};

export type FindingGroupsReadPathParams = {
  /**
   * A UUID string identifying this finding group.
   *
   * @format uuid
   */
  id: string;
};

export type FindingGroupsReadError = Fetcher.ErrorWrapper<undefined>;

export type FindingGroupsReadVariables = {
  pathParams: FindingGroupsReadPathParams;
} & OneApiContext['fetcherOptions'];

export const fetchFindingGroupsRead = (
  variables: FindingGroupsReadVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    Schemas.FindingGroup,
    FindingGroupsReadError,
    undefined,
    {},
    {},
    FindingGroupsReadPathParams
  >({ url: '/finding-groups/{id}', method: 'get', ...variables, signal });

export const useFindingGroupsRead = <TData = Schemas.FindingGroup>(
  variables: FindingGroupsReadVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.FindingGroup,
      FindingGroupsReadError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<
    Schemas.FindingGroup,
    FindingGroupsReadError,
    TData
  >(
    queryKeyFn({
      path: '/finding-groups/{id}',
      operationId: 'findingGroupsRead',
      variables,
    }),
    ({ signal }) =>
      fetchFindingGroupsRead({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type FindingGroupsFindingAreasListPathParams = {
  id: string;
};

export type FindingGroupsFindingAreasListError =
  Fetcher.ErrorWrapper<undefined>;

export type FindingGroupsFindingAreasListResponse = Schemas.FindingArea[];

export type FindingGroupsFindingAreasListVariables = {
  pathParams: FindingGroupsFindingAreasListPathParams;
} & OneApiContext['fetcherOptions'];

export const fetchFindingGroupsFindingAreasList = (
  variables: FindingGroupsFindingAreasListVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    FindingGroupsFindingAreasListResponse,
    FindingGroupsFindingAreasListError,
    undefined,
    {},
    {},
    FindingGroupsFindingAreasListPathParams
  >({
    url: '/finding-groups/{id}/finding-areas',
    method: 'get',
    ...variables,
    signal,
  });

export const useFindingGroupsFindingAreasList = <
  TData = FindingGroupsFindingAreasListResponse
>(
  variables: FindingGroupsFindingAreasListVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      FindingGroupsFindingAreasListResponse,
      FindingGroupsFindingAreasListError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<
    FindingGroupsFindingAreasListResponse,
    FindingGroupsFindingAreasListError,
    TData
  >(
    queryKeyFn({
      path: '/finding-groups/{id}/finding-areas',
      operationId: 'findingGroupsFindingAreasList',
      variables,
    }),
    ({ signal }) =>
      fetchFindingGroupsFindingAreasList(
        { ...fetcherOptions, ...variables },
        signal
      ),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type FindingGroupsRatingListPathParams = {
  id: string;
};

export type FindingGroupsRatingListError = Fetcher.ErrorWrapper<undefined>;

export type FindingGroupsRatingListVariables = {
  pathParams: FindingGroupsRatingListPathParams;
} & OneApiContext['fetcherOptions'];

export const fetchFindingGroupsRatingList = (
  variables: FindingGroupsRatingListVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    undefined,
    FindingGroupsRatingListError,
    undefined,
    {},
    {},
    FindingGroupsRatingListPathParams
  >({
    url: '/finding-groups/{id}/rating',
    method: 'get',
    ...variables,
    signal,
  });

export const useFindingGroupsRatingList = <TData = undefined>(
  variables: FindingGroupsRatingListVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<undefined, FindingGroupsRatingListError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<undefined, FindingGroupsRatingListError, TData>(
    queryKeyFn({
      path: '/finding-groups/{id}/rating',
      operationId: 'findingGroupsRatingList',
      variables,
    }),
    ({ signal }) =>
      fetchFindingGroupsRatingList({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type FindingGroupsTasRatingListPathParams = {
  id: string;
};

export type FindingGroupsTasRatingListError = Fetcher.ErrorWrapper<undefined>;

export type FindingGroupsTasRatingListVariables = {
  pathParams: FindingGroupsTasRatingListPathParams;
} & OneApiContext['fetcherOptions'];

export const fetchFindingGroupsTasRatingList = (
  variables: FindingGroupsTasRatingListVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    undefined,
    FindingGroupsTasRatingListError,
    undefined,
    {},
    {},
    FindingGroupsTasRatingListPathParams
  >({
    url: '/finding-groups/{id}/tas_rating',
    method: 'get',
    ...variables,
    signal,
  });

export const useFindingGroupsTasRatingList = <TData = undefined>(
  variables: FindingGroupsTasRatingListVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      undefined,
      FindingGroupsTasRatingListError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<undefined, FindingGroupsTasRatingListError, TData>(
    queryKeyFn({
      path: '/finding-groups/{id}/tas_rating',
      operationId: 'findingGroupsTasRatingList',
      variables,
    }),
    ({ signal }) =>
      fetchFindingGroupsTasRatingList(
        { ...fetcherOptions, ...variables },
        signal
      ),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type FindingReferenceCreateError = Fetcher.ErrorWrapper<undefined>;

export type FindingReferenceCreateVariables = {
  body: RequestBodies.FindingReference;
} & OneApiContext['fetcherOptions'];

export const fetchFindingReferenceCreate = (
  variables: FindingReferenceCreateVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    Schemas.FindingReference,
    FindingReferenceCreateError,
    RequestBodies.FindingReference,
    {},
    {},
    {}
  >({ url: '/finding-reference', method: 'post', ...variables, signal });

export const useFindingReferenceCreate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.FindingReference,
      FindingReferenceCreateError,
      FindingReferenceCreateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useOneApiContext();
  return reactQuery.useMutation<
    Schemas.FindingReference,
    FindingReferenceCreateError,
    FindingReferenceCreateVariables
  >(
    (variables: FindingReferenceCreateVariables) =>
      fetchFindingReferenceCreate({ ...fetcherOptions, ...variables }),
    options
  );
};

export type FindingReferenceReadPathParams = {
  /**
   * A UUID string identifying this finding reference.
   *
   * @format uuid
   */
  id: string;
};

export type FindingReferenceReadError = Fetcher.ErrorWrapper<undefined>;

export type FindingReferenceReadVariables = {
  pathParams: FindingReferenceReadPathParams;
} & OneApiContext['fetcherOptions'];

export const fetchFindingReferenceRead = (
  variables: FindingReferenceReadVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    Schemas.FindingReference,
    FindingReferenceReadError,
    undefined,
    {},
    {},
    FindingReferenceReadPathParams
  >({ url: '/finding-reference/{id}', method: 'get', ...variables, signal });

export const useFindingReferenceRead = <TData = Schemas.FindingReference>(
  variables: FindingReferenceReadVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.FindingReference,
      FindingReferenceReadError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<
    Schemas.FindingReference,
    FindingReferenceReadError,
    TData
  >(
    queryKeyFn({
      path: '/finding-reference/{id}',
      operationId: 'findingReferenceRead',
      variables,
    }),
    ({ signal }) =>
      fetchFindingReferenceRead({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type FindingReferenceUpdatePathParams = {
  /**
   * A UUID string identifying this finding reference.
   *
   * @format uuid
   */
  id: string;
};

export type FindingReferenceUpdateError = Fetcher.ErrorWrapper<undefined>;

export type FindingReferenceUpdateVariables = {
  body: RequestBodies.FindingReference;
  pathParams: FindingReferenceUpdatePathParams;
} & OneApiContext['fetcherOptions'];

export const fetchFindingReferenceUpdate = (
  variables: FindingReferenceUpdateVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    Schemas.FindingReference,
    FindingReferenceUpdateError,
    RequestBodies.FindingReference,
    {},
    {},
    FindingReferenceUpdatePathParams
  >({ url: '/finding-reference/{id}', method: 'put', ...variables, signal });

export const useFindingReferenceUpdate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.FindingReference,
      FindingReferenceUpdateError,
      FindingReferenceUpdateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useOneApiContext();
  return reactQuery.useMutation<
    Schemas.FindingReference,
    FindingReferenceUpdateError,
    FindingReferenceUpdateVariables
  >(
    (variables: FindingReferenceUpdateVariables) =>
      fetchFindingReferenceUpdate({ ...fetcherOptions, ...variables }),
    options
  );
};

export type FindingReferenceDeletePathParams = {
  /**
   * A UUID string identifying this finding reference.
   *
   * @format uuid
   */
  id: string;
};

export type FindingReferenceDeleteError = Fetcher.ErrorWrapper<undefined>;

export type FindingReferenceDeleteVariables = {
  pathParams: FindingReferenceDeletePathParams;
} & OneApiContext['fetcherOptions'];

export const fetchFindingReferenceDelete = (
  variables: FindingReferenceDeleteVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    undefined,
    FindingReferenceDeleteError,
    undefined,
    {},
    {},
    FindingReferenceDeletePathParams
  >({ url: '/finding-reference/{id}', method: 'delete', ...variables, signal });

export const useFindingReferenceDelete = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      FindingReferenceDeleteError,
      FindingReferenceDeleteVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useOneApiContext();
  return reactQuery.useMutation<
    undefined,
    FindingReferenceDeleteError,
    FindingReferenceDeleteVariables
  >(
    (variables: FindingReferenceDeleteVariables) =>
      fetchFindingReferenceDelete({ ...fetcherOptions, ...variables }),
    options
  );
};

export type FindingTopicReferenceCreateError = Fetcher.ErrorWrapper<undefined>;

export type FindingTopicReferenceCreateVariables = {
  body: RequestBodies.FindingTopicReference;
} & OneApiContext['fetcherOptions'];

export const fetchFindingTopicReferenceCreate = (
  variables: FindingTopicReferenceCreateVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    Schemas.FindingTopicReference,
    FindingTopicReferenceCreateError,
    RequestBodies.FindingTopicReference,
    {},
    {},
    {}
  >({ url: '/finding-topic-reference', method: 'post', ...variables, signal });

export const useFindingTopicReferenceCreate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.FindingTopicReference,
      FindingTopicReferenceCreateError,
      FindingTopicReferenceCreateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useOneApiContext();
  return reactQuery.useMutation<
    Schemas.FindingTopicReference,
    FindingTopicReferenceCreateError,
    FindingTopicReferenceCreateVariables
  >(
    (variables: FindingTopicReferenceCreateVariables) =>
      fetchFindingTopicReferenceCreate({ ...fetcherOptions, ...variables }),
    options
  );
};

export type FindingTopicReferenceReadPathParams = {
  /**
   * A UUID string identifying this finding topic reference.
   *
   * @format uuid
   */
  id: string;
};

export type FindingTopicReferenceReadError = Fetcher.ErrorWrapper<undefined>;

export type FindingTopicReferenceReadVariables = {
  pathParams: FindingTopicReferenceReadPathParams;
} & OneApiContext['fetcherOptions'];

export const fetchFindingTopicReferenceRead = (
  variables: FindingTopicReferenceReadVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    Schemas.FindingTopicReference,
    FindingTopicReferenceReadError,
    undefined,
    {},
    {},
    FindingTopicReferenceReadPathParams
  >({
    url: '/finding-topic-reference/{id}',
    method: 'get',
    ...variables,
    signal,
  });

export const useFindingTopicReferenceRead = <
  TData = Schemas.FindingTopicReference
>(
  variables: FindingTopicReferenceReadVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.FindingTopicReference,
      FindingTopicReferenceReadError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<
    Schemas.FindingTopicReference,
    FindingTopicReferenceReadError,
    TData
  >(
    queryKeyFn({
      path: '/finding-topic-reference/{id}',
      operationId: 'findingTopicReferenceRead',
      variables,
    }),
    ({ signal }) =>
      fetchFindingTopicReferenceRead(
        { ...fetcherOptions, ...variables },
        signal
      ),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type FindingTopicReferenceUpdatePathParams = {
  /**
   * A UUID string identifying this finding topic reference.
   *
   * @format uuid
   */
  id: string;
};

export type FindingTopicReferenceUpdateError = Fetcher.ErrorWrapper<undefined>;

export type FindingTopicReferenceUpdateVariables = {
  body: RequestBodies.FindingTopicReference;
  pathParams: FindingTopicReferenceUpdatePathParams;
} & OneApiContext['fetcherOptions'];

export const fetchFindingTopicReferenceUpdate = (
  variables: FindingTopicReferenceUpdateVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    Schemas.FindingTopicReference,
    FindingTopicReferenceUpdateError,
    RequestBodies.FindingTopicReference,
    {},
    {},
    FindingTopicReferenceUpdatePathParams
  >({
    url: '/finding-topic-reference/{id}',
    method: 'put',
    ...variables,
    signal,
  });

export const useFindingTopicReferenceUpdate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.FindingTopicReference,
      FindingTopicReferenceUpdateError,
      FindingTopicReferenceUpdateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useOneApiContext();
  return reactQuery.useMutation<
    Schemas.FindingTopicReference,
    FindingTopicReferenceUpdateError,
    FindingTopicReferenceUpdateVariables
  >(
    (variables: FindingTopicReferenceUpdateVariables) =>
      fetchFindingTopicReferenceUpdate({ ...fetcherOptions, ...variables }),
    options
  );
};

export type FindingTopicReferenceDeletePathParams = {
  /**
   * A UUID string identifying this finding topic reference.
   *
   * @format uuid
   */
  id: string;
};

export type FindingTopicReferenceDeleteError = Fetcher.ErrorWrapper<undefined>;

export type FindingTopicReferenceDeleteVariables = {
  pathParams: FindingTopicReferenceDeletePathParams;
} & OneApiContext['fetcherOptions'];

export const fetchFindingTopicReferenceDelete = (
  variables: FindingTopicReferenceDeleteVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    undefined,
    FindingTopicReferenceDeleteError,
    undefined,
    {},
    {},
    FindingTopicReferenceDeletePathParams
  >({
    url: '/finding-topic-reference/{id}',
    method: 'delete',
    ...variables,
    signal,
  });

export const useFindingTopicReferenceDelete = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      FindingTopicReferenceDeleteError,
      FindingTopicReferenceDeleteVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useOneApiContext();
  return reactQuery.useMutation<
    undefined,
    FindingTopicReferenceDeleteError,
    FindingTopicReferenceDeleteVariables
  >(
    (variables: FindingTopicReferenceDeleteVariables) =>
      fetchFindingTopicReferenceDelete({ ...fetcherOptions, ...variables }),
    options
  );
};

export type FindingTopicReadPathParams = {
  /**
   * A UUID string identifying this finding topic.
   *
   * @format uuid
   */
  id: string;
};

export type FindingTopicReadError = Fetcher.ErrorWrapper<undefined>;

export type FindingTopicReadVariables = {
  pathParams: FindingTopicReadPathParams;
} & OneApiContext['fetcherOptions'];

export const fetchFindingTopicRead = (
  variables: FindingTopicReadVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    Schemas.FindingTopic,
    FindingTopicReadError,
    undefined,
    {},
    {},
    FindingTopicReadPathParams
  >({ url: '/finding-topic/{id}', method: 'get', ...variables, signal });

export const useFindingTopicRead = <TData = Schemas.FindingTopic>(
  variables: FindingTopicReadVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.FindingTopic,
      FindingTopicReadError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<
    Schemas.FindingTopic,
    FindingTopicReadError,
    TData
  >(
    queryKeyFn({
      path: '/finding-topic/{id}',
      operationId: 'findingTopicRead',
      variables,
    }),
    ({ signal }) =>
      fetchFindingTopicRead({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type FindingTopicUpdatePathParams = {
  /**
   * A UUID string identifying this finding topic.
   *
   * @format uuid
   */
  id: string;
};

export type FindingTopicUpdateError = Fetcher.ErrorWrapper<undefined>;

export type FindingTopicUpdateVariables = {
  body: Schemas.FindingTopic;
  pathParams: FindingTopicUpdatePathParams;
} & OneApiContext['fetcherOptions'];

export const fetchFindingTopicUpdate = (
  variables: FindingTopicUpdateVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    Schemas.FindingTopic,
    FindingTopicUpdateError,
    Schemas.FindingTopic,
    {},
    {},
    FindingTopicUpdatePathParams
  >({ url: '/finding-topic/{id}', method: 'put', ...variables, signal });

export const useFindingTopicUpdate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.FindingTopic,
      FindingTopicUpdateError,
      FindingTopicUpdateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useOneApiContext();
  return reactQuery.useMutation<
    Schemas.FindingTopic,
    FindingTopicUpdateError,
    FindingTopicUpdateVariables
  >(
    (variables: FindingTopicUpdateVariables) =>
      fetchFindingTopicUpdate({ ...fetcherOptions, ...variables }),
    options
  );
};

export type FindingTopicFindingTopicReferencesListPathParams = {
  id: string;
};

export type FindingTopicFindingTopicReferencesListError =
  Fetcher.ErrorWrapper<undefined>;

export type FindingTopicFindingTopicReferencesListVariables = {
  pathParams: FindingTopicFindingTopicReferencesListPathParams;
} & OneApiContext['fetcherOptions'];

export const fetchFindingTopicFindingTopicReferencesList = (
  variables: FindingTopicFindingTopicReferencesListVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    undefined,
    FindingTopicFindingTopicReferencesListError,
    undefined,
    {},
    {},
    FindingTopicFindingTopicReferencesListPathParams
  >({
    url: '/finding-topic/{id}/finding-topic-references',
    method: 'get',
    ...variables,
    signal,
  });

export const useFindingTopicFindingTopicReferencesList = <TData = undefined>(
  variables: FindingTopicFindingTopicReferencesListVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      undefined,
      FindingTopicFindingTopicReferencesListError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<
    undefined,
    FindingTopicFindingTopicReferencesListError,
    TData
  >(
    queryKeyFn({
      path: '/finding-topic/{id}/finding-topic-references',
      operationId: 'findingTopicFindingTopicReferencesList',
      variables,
    }),
    ({ signal }) =>
      fetchFindingTopicFindingTopicReferencesList(
        { ...fetcherOptions, ...variables },
        signal
      ),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type FindingTopicMaturityRatingsListPathParams = {
  id: string;
};

export type FindingTopicMaturityRatingsListError =
  Fetcher.ErrorWrapper<undefined>;

export type FindingTopicMaturityRatingsListResponse = Schemas.MaturityRating[];

export type FindingTopicMaturityRatingsListVariables = {
  pathParams: FindingTopicMaturityRatingsListPathParams;
} & OneApiContext['fetcherOptions'];

export const fetchFindingTopicMaturityRatingsList = (
  variables: FindingTopicMaturityRatingsListVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    FindingTopicMaturityRatingsListResponse,
    FindingTopicMaturityRatingsListError,
    undefined,
    {},
    {},
    FindingTopicMaturityRatingsListPathParams
  >({
    url: '/finding-topic/{id}/maturity-ratings',
    method: 'get',
    ...variables,
    signal,
  });

export const useFindingTopicMaturityRatingsList = <
  TData = FindingTopicMaturityRatingsListResponse
>(
  variables: FindingTopicMaturityRatingsListVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      FindingTopicMaturityRatingsListResponse,
      FindingTopicMaturityRatingsListError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<
    FindingTopicMaturityRatingsListResponse,
    FindingTopicMaturityRatingsListError,
    TData
  >(
    queryKeyFn({
      path: '/finding-topic/{id}/maturity-ratings',
      operationId: 'findingTopicMaturityRatingsList',
      variables,
    }),
    ({ signal }) =>
      fetchFindingTopicMaturityRatingsList(
        { ...fetcherOptions, ...variables },
        signal
      ),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type FindingTopicMaturityRatingsCreatePathParams = {
  id: string;
};

export type FindingTopicMaturityRatingsCreateError =
  Fetcher.ErrorWrapper<undefined>;

export type FindingTopicMaturityRatingsCreateVariables = {
  body: RequestBodies.MaturityRating;
  pathParams: FindingTopicMaturityRatingsCreatePathParams;
} & OneApiContext['fetcherOptions'];

export const fetchFindingTopicMaturityRatingsCreate = (
  variables: FindingTopicMaturityRatingsCreateVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    Schemas.MaturityRating,
    FindingTopicMaturityRatingsCreateError,
    RequestBodies.MaturityRating,
    {},
    {},
    FindingTopicMaturityRatingsCreatePathParams
  >({
    url: '/finding-topic/{id}/maturity-ratings',
    method: 'post',
    ...variables,
    signal,
  });

export const useFindingTopicMaturityRatingsCreate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.MaturityRating,
      FindingTopicMaturityRatingsCreateError,
      FindingTopicMaturityRatingsCreateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useOneApiContext();
  return reactQuery.useMutation<
    Schemas.MaturityRating,
    FindingTopicMaturityRatingsCreateError,
    FindingTopicMaturityRatingsCreateVariables
  >(
    (variables: FindingTopicMaturityRatingsCreateVariables) =>
      fetchFindingTopicMaturityRatingsCreate({
        ...fetcherOptions,
        ...variables,
      }),
    options
  );
};

export type FindingTopicAssessmentQuestionsListPathParams = {
  topicPk: string;
};

export type FindingTopicAssessmentQuestionsListError =
  Fetcher.ErrorWrapper<undefined>;

export type FindingTopicAssessmentQuestionsListResponse =
  Schemas.AssessmentQuestion[];

export type FindingTopicAssessmentQuestionsListVariables = {
  pathParams: FindingTopicAssessmentQuestionsListPathParams;
} & OneApiContext['fetcherOptions'];

export const fetchFindingTopicAssessmentQuestionsList = (
  variables: FindingTopicAssessmentQuestionsListVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    FindingTopicAssessmentQuestionsListResponse,
    FindingTopicAssessmentQuestionsListError,
    undefined,
    {},
    {},
    FindingTopicAssessmentQuestionsListPathParams
  >({
    url: '/finding-topic/{topicPk}/assessment-questions',
    method: 'get',
    ...variables,
    signal,
  });

export const useFindingTopicAssessmentQuestionsList = <
  TData = FindingTopicAssessmentQuestionsListResponse
>(
  variables: FindingTopicAssessmentQuestionsListVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      FindingTopicAssessmentQuestionsListResponse,
      FindingTopicAssessmentQuestionsListError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<
    FindingTopicAssessmentQuestionsListResponse,
    FindingTopicAssessmentQuestionsListError,
    TData
  >(
    queryKeyFn({
      path: '/finding-topic/{topic_pk}/assessment-questions',
      operationId: 'findingTopicAssessmentQuestionsList',
      variables,
    }),
    ({ signal }) =>
      fetchFindingTopicAssessmentQuestionsList(
        { ...fetcherOptions, ...variables },
        signal
      ),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type FindingsCreateError = Fetcher.ErrorWrapper<undefined>;

export type FindingsCreateVariables = {
  body: RequestBodies.Finding;
} & OneApiContext['fetcherOptions'];

export const fetchFindingsCreate = (
  variables: FindingsCreateVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    Schemas.Finding,
    FindingsCreateError,
    RequestBodies.Finding,
    {},
    {},
    {}
  >({ url: '/findings/', method: 'post', ...variables, signal });

export const useFindingsCreate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.Finding,
      FindingsCreateError,
      FindingsCreateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useOneApiContext();
  return reactQuery.useMutation<
    Schemas.Finding,
    FindingsCreateError,
    FindingsCreateVariables
  >(
    (variables: FindingsCreateVariables) =>
      fetchFindingsCreate({ ...fetcherOptions, ...variables }),
    options
  );
};

export type FindingsReadPathParams = {
  /**
   * A UUID string identifying this finding.
   *
   * @format uuid
   */
  id: string;
};

export type FindingsReadError = Fetcher.ErrorWrapper<undefined>;

export type FindingsReadVariables = {
  pathParams: FindingsReadPathParams;
} & OneApiContext['fetcherOptions'];

export const fetchFindingsRead = (
  variables: FindingsReadVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    Schemas.Finding,
    FindingsReadError,
    undefined,
    {},
    {},
    FindingsReadPathParams
  >({ url: '/findings/{id}', method: 'get', ...variables, signal });

export const useFindingsRead = <TData = Schemas.Finding>(
  variables: FindingsReadVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.Finding, FindingsReadError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<Schemas.Finding, FindingsReadError, TData>(
    queryKeyFn({
      path: '/findings/{id}',
      operationId: 'findingsRead',
      variables,
    }),
    ({ signal }) =>
      fetchFindingsRead({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type FindingsUpdatePathParams = {
  /**
   * A UUID string identifying this finding.
   *
   * @format uuid
   */
  id: string;
};

export type FindingsUpdateError = Fetcher.ErrorWrapper<undefined>;

export type FindingsUpdateVariables = {
  body: RequestBodies.Finding;
  pathParams: FindingsUpdatePathParams;
} & OneApiContext['fetcherOptions'];

export const fetchFindingsUpdate = (
  variables: FindingsUpdateVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    Schemas.Finding,
    FindingsUpdateError,
    RequestBodies.Finding,
    {},
    {},
    FindingsUpdatePathParams
  >({ url: '/findings/{id}', method: 'put', ...variables, signal });

export const useFindingsUpdate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.Finding,
      FindingsUpdateError,
      FindingsUpdateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useOneApiContext();
  return reactQuery.useMutation<
    Schemas.Finding,
    FindingsUpdateError,
    FindingsUpdateVariables
  >(
    (variables: FindingsUpdateVariables) =>
      fetchFindingsUpdate({ ...fetcherOptions, ...variables }),
    options
  );
};

export type FindingsDeletePathParams = {
  /**
   * A UUID string identifying this finding.
   *
   * @format uuid
   */
  id: string;
};

export type FindingsDeleteError = Fetcher.ErrorWrapper<undefined>;

export type FindingsDeleteVariables = {
  pathParams: FindingsDeletePathParams;
} & OneApiContext['fetcherOptions'];

export const fetchFindingsDelete = (
  variables: FindingsDeleteVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    undefined,
    FindingsDeleteError,
    undefined,
    {},
    {},
    FindingsDeletePathParams
  >({ url: '/findings/{id}', method: 'delete', ...variables, signal });

export const useFindingsDelete = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      FindingsDeleteError,
      FindingsDeleteVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useOneApiContext();
  return reactQuery.useMutation<
    undefined,
    FindingsDeleteError,
    FindingsDeleteVariables
  >(
    (variables: FindingsDeleteVariables) =>
      fetchFindingsDelete({ ...fetcherOptions, ...variables }),
    options
  );
};

export type FindingsFindingReferencesListPathParams = {
  id: string;
};

export type FindingsFindingReferencesListError =
  Fetcher.ErrorWrapper<undefined>;

export type FindingsFindingReferencesListVariables = {
  pathParams: FindingsFindingReferencesListPathParams;
} & OneApiContext['fetcherOptions'];

export const fetchFindingsFindingReferencesList = (
  variables: FindingsFindingReferencesListVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    undefined,
    FindingsFindingReferencesListError,
    undefined,
    {},
    {},
    FindingsFindingReferencesListPathParams
  >({
    url: '/findings/{id}/finding-references',
    method: 'get',
    ...variables,
    signal,
  });

export const useFindingsFindingReferencesList = <TData = undefined>(
  variables: FindingsFindingReferencesListVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      undefined,
      FindingsFindingReferencesListError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<
    undefined,
    FindingsFindingReferencesListError,
    TData
  >(
    queryKeyFn({
      path: '/findings/{id}/finding-references',
      operationId: 'findingsFindingReferencesList',
      variables,
    }),
    ({ signal }) =>
      fetchFindingsFindingReferencesList(
        { ...fetcherOptions, ...variables },
        signal
      ),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type MaturityRatingReadPathParams = {
  /**
   * A UUID string identifying this maturity rating.
   *
   * @format uuid
   */
  id: string;
};

export type MaturityRatingReadError = Fetcher.ErrorWrapper<undefined>;

export type MaturityRatingReadVariables = {
  pathParams: MaturityRatingReadPathParams;
} & OneApiContext['fetcherOptions'];

export const fetchMaturityRatingRead = (
  variables: MaturityRatingReadVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    Schemas.MaturityRating,
    MaturityRatingReadError,
    undefined,
    {},
    {},
    MaturityRatingReadPathParams
  >({ url: '/maturity-rating/{id}', method: 'get', ...variables, signal });

export const useMaturityRatingRead = <TData = Schemas.MaturityRating>(
  variables: MaturityRatingReadVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.MaturityRating,
      MaturityRatingReadError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<
    Schemas.MaturityRating,
    MaturityRatingReadError,
    TData
  >(
    queryKeyFn({
      path: '/maturity-rating/{id}',
      operationId: 'maturityRatingRead',
      variables,
    }),
    ({ signal }) =>
      fetchMaturityRatingRead({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type MaturityRatingUpdatePathParams = {
  /**
   * A UUID string identifying this maturity rating.
   *
   * @format uuid
   */
  id: string;
};

export type MaturityRatingUpdateError = Fetcher.ErrorWrapper<undefined>;

export type MaturityRatingUpdateVariables = {
  body: RequestBodies.MaturityRating;
  pathParams: MaturityRatingUpdatePathParams;
} & OneApiContext['fetcherOptions'];

export const fetchMaturityRatingUpdate = (
  variables: MaturityRatingUpdateVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    Schemas.MaturityRating,
    MaturityRatingUpdateError,
    RequestBodies.MaturityRating,
    {},
    {},
    MaturityRatingUpdatePathParams
  >({ url: '/maturity-rating/{id}', method: 'put', ...variables, signal });

export const useMaturityRatingUpdate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.MaturityRating,
      MaturityRatingUpdateError,
      MaturityRatingUpdateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useOneApiContext();
  return reactQuery.useMutation<
    Schemas.MaturityRating,
    MaturityRatingUpdateError,
    MaturityRatingUpdateVariables
  >(
    (variables: MaturityRatingUpdateVariables) =>
      fetchMaturityRatingUpdate({ ...fetcherOptions, ...variables }),
    options
  );
};

export type MaturityViewFindingGroupsTasBenchmarkListPathParams = {
  ident: string;
};

export type MaturityViewFindingGroupsTasBenchmarkListError =
  Fetcher.ErrorWrapper<undefined>;

export type MaturityViewFindingGroupsTasBenchmarkListVariables = {
  pathParams: MaturityViewFindingGroupsTasBenchmarkListPathParams;
} & OneApiContext['fetcherOptions'];

export const fetchMaturityViewFindingGroupsTasBenchmarkList = (
  variables: MaturityViewFindingGroupsTasBenchmarkListVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    undefined,
    MaturityViewFindingGroupsTasBenchmarkListError,
    undefined,
    {},
    {},
    MaturityViewFindingGroupsTasBenchmarkListPathParams
  >({
    url: '/maturity-view/{ident}/finding-groups-tas-benchmark',
    method: 'get',
    ...variables,
    signal,
  });

export const useMaturityViewFindingGroupsTasBenchmarkList = <TData = undefined>(
  variables: MaturityViewFindingGroupsTasBenchmarkListVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      undefined,
      MaturityViewFindingGroupsTasBenchmarkListError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<
    undefined,
    MaturityViewFindingGroupsTasBenchmarkListError,
    TData
  >(
    queryKeyFn({
      path: '/maturity-view/{ident}/finding-groups-tas-benchmark',
      operationId: 'maturityViewFindingGroupsTasBenchmarkList',
      variables,
    }),
    ({ signal }) =>
      fetchMaturityViewFindingGroupsTasBenchmarkList(
        { ...fetcherOptions, ...variables },
        signal
      ),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type MaturityViewFindingGroupsListPathParams = {
  id: string;
};

export type MaturityViewFindingGroupsListError =
  Fetcher.ErrorWrapper<undefined>;

export type MaturityViewFindingGroupsListResponse = Schemas.FindingGroup[];

export type MaturityViewFindingGroupsListVariables = {
  pathParams: MaturityViewFindingGroupsListPathParams;
} & OneApiContext['fetcherOptions'];

export const fetchMaturityViewFindingGroupsList = (
  variables: MaturityViewFindingGroupsListVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    MaturityViewFindingGroupsListResponse,
    MaturityViewFindingGroupsListError,
    undefined,
    {},
    {},
    MaturityViewFindingGroupsListPathParams
  >({
    url: '/maturity-view/{id}/finding-groups',
    method: 'get',
    ...variables,
    signal,
  });

export const useMaturityViewFindingGroupsList = <
  TData = MaturityViewFindingGroupsListResponse
>(
  variables: MaturityViewFindingGroupsListVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      MaturityViewFindingGroupsListResponse,
      MaturityViewFindingGroupsListError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<
    MaturityViewFindingGroupsListResponse,
    MaturityViewFindingGroupsListError,
    TData
  >(
    queryKeyFn({
      path: '/maturity-view/{id}/finding-groups',
      operationId: 'maturityViewFindingGroupsList',
      variables,
    }),
    ({ signal }) =>
      fetchMaturityViewFindingGroupsList(
        { ...fetcherOptions, ...variables },
        signal
      ),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type MaturityViewMaturityBulkReadPathParams = {
  /**
   * A UUID string identifying this maturity view.
   *
   * @format uuid
   */
  id: string;
};

export type MaturityViewMaturityBulkReadError = Fetcher.ErrorWrapper<undefined>;

export type MaturityViewMaturityBulkReadVariables = {
  pathParams: MaturityViewMaturityBulkReadPathParams;
} & OneApiContext['fetcherOptions'];

export const fetchMaturityViewMaturityBulkRead = (
  variables: MaturityViewMaturityBulkReadVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    Schemas.MaturityMaturityView,
    MaturityViewMaturityBulkReadError,
    undefined,
    {},
    {},
    MaturityViewMaturityBulkReadPathParams
  >({
    url: '/maturity-view/{id}/maturity-bulk',
    method: 'get',
    ...variables,
    signal,
  });

export const useMaturityViewMaturityBulkRead = <
  TData = Schemas.MaturityMaturityView
>(
  variables: MaturityViewMaturityBulkReadVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.MaturityMaturityView,
      MaturityViewMaturityBulkReadError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<
    Schemas.MaturityMaturityView,
    MaturityViewMaturityBulkReadError,
    TData
  >(
    queryKeyFn({
      path: '/maturity-view/{id}/maturity-bulk',
      operationId: 'maturityViewMaturityBulkRead',
      variables,
    }),
    ({ signal }) =>
      fetchMaturityViewMaturityBulkRead(
        { ...fetcherOptions, ...variables },
        signal
      ),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type OptionsReadPathParams = {
  /**
   * Option type
   */
  type: 'project_team_role';
};

export type OptionsReadError = Fetcher.ErrorWrapper<undefined>;

export type OptionsReadResponse = Schemas.Option[];

export type OptionsReadVariables = {
  pathParams: OptionsReadPathParams;
} & OneApiContext['fetcherOptions'];

export const fetchOptionsRead = (
  variables: OptionsReadVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    OptionsReadResponse,
    OptionsReadError,
    undefined,
    {},
    {},
    OptionsReadPathParams
  >({ url: '/options/{type}', method: 'get', ...variables, signal });

export const useOptionsRead = <TData = OptionsReadResponse>(
  variables: OptionsReadVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<OptionsReadResponse, OptionsReadError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<OptionsReadResponse, OptionsReadError, TData>(
    queryKeyFn({
      path: '/options/{type}',
      operationId: 'optionsRead',
      variables,
    }),
    ({ signal }) =>
      fetchOptionsRead({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type ProjectCollaboratorsListError = Fetcher.ErrorWrapper<undefined>;

export type ProjectCollaboratorsListVariables = OneApiContext['fetcherOptions'];

/**
 * List all users that may collaborate on projects
 */
export const fetchProjectCollaboratorsList = (
  variables: ProjectCollaboratorsListVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<undefined, ProjectCollaboratorsListError, undefined, {}, {}, {}>({
    url: '/project-collaborators',
    method: 'get',
    ...variables,
    signal,
  });

/**
 * List all users that may collaborate on projects
 */
export const useProjectCollaboratorsList = <TData = undefined>(
  variables: ProjectCollaboratorsListVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<undefined, ProjectCollaboratorsListError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<undefined, ProjectCollaboratorsListError, TData>(
    queryKeyFn({
      path: '/project-collaborators',
      operationId: 'projectCollaboratorsList',
      variables,
    }),
    ({ signal }) =>
      fetchProjectCollaboratorsList(
        { ...fetcherOptions, ...variables },
        signal
      ),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type ProjectCollaboratorsCreateError = Fetcher.ErrorWrapper<undefined>;

export type ProjectCollaboratorsCreateVariables = {
  body: Schemas.CreateNewUser;
} & OneApiContext['fetcherOptions'];

/**
 * Creates an new user on Auth0 and assigned it to given project.
 */
export const fetchProjectCollaboratorsCreate = (
  variables: ProjectCollaboratorsCreateVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    undefined,
    ProjectCollaboratorsCreateError,
    Schemas.CreateNewUser,
    {},
    {},
    {}
  >({ url: '/project-collaborators', method: 'post', ...variables, signal });

/**
 * Creates an new user on Auth0 and assigned it to given project.
 */
export const useProjectCollaboratorsCreate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      ProjectCollaboratorsCreateError,
      ProjectCollaboratorsCreateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useOneApiContext();
  return reactQuery.useMutation<
    undefined,
    ProjectCollaboratorsCreateError,
    ProjectCollaboratorsCreateVariables
  >(
    (variables: ProjectCollaboratorsCreateVariables) =>
      fetchProjectCollaboratorsCreate({ ...fetcherOptions, ...variables }),
    options
  );
};

export type ProjectTeamMembersCreateError = Fetcher.ErrorWrapper<undefined>;

export type ProjectTeamMembersCreateVariables = {
  body: RequestBodies.ProjectTeamMember;
} & OneApiContext['fetcherOptions'];

export const fetchProjectTeamMembersCreate = (
  variables: ProjectTeamMembersCreateVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    Schemas.ProjectTeamMember,
    ProjectTeamMembersCreateError,
    RequestBodies.ProjectTeamMember,
    {},
    {},
    {}
  >({ url: '/project-team-members', method: 'post', ...variables, signal });

export const useProjectTeamMembersCreate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.ProjectTeamMember,
      ProjectTeamMembersCreateError,
      ProjectTeamMembersCreateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useOneApiContext();
  return reactQuery.useMutation<
    Schemas.ProjectTeamMember,
    ProjectTeamMembersCreateError,
    ProjectTeamMembersCreateVariables
  >(
    (variables: ProjectTeamMembersCreateVariables) =>
      fetchProjectTeamMembersCreate({ ...fetcherOptions, ...variables }),
    options
  );
};

export type ProjectTeamMembersReadPathParams = {
  /**
   * A UUID string identifying this project team member.
   *
   * @format uuid
   */
  id: string;
};

export type ProjectTeamMembersReadError = Fetcher.ErrorWrapper<undefined>;

export type ProjectTeamMembersReadVariables = {
  pathParams: ProjectTeamMembersReadPathParams;
} & OneApiContext['fetcherOptions'];

export const fetchProjectTeamMembersRead = (
  variables: ProjectTeamMembersReadVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    Schemas.ProjectTeamMember,
    ProjectTeamMembersReadError,
    undefined,
    {},
    {},
    ProjectTeamMembersReadPathParams
  >({ url: '/project-team-members/{id}', method: 'get', ...variables, signal });

export const useProjectTeamMembersRead = <TData = Schemas.ProjectTeamMember>(
  variables: ProjectTeamMembersReadVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.ProjectTeamMember,
      ProjectTeamMembersReadError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<
    Schemas.ProjectTeamMember,
    ProjectTeamMembersReadError,
    TData
  >(
    queryKeyFn({
      path: '/project-team-members/{id}',
      operationId: 'projectTeamMembersRead',
      variables,
    }),
    ({ signal }) =>
      fetchProjectTeamMembersRead({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type ProjectTeamMembersUpdatePathParams = {
  /**
   * A UUID string identifying this project team member.
   *
   * @format uuid
   */
  id: string;
};

export type ProjectTeamMembersUpdateError = Fetcher.ErrorWrapper<undefined>;

export type ProjectTeamMembersUpdateVariables = {
  body: RequestBodies.ProjectTeamMember;
  pathParams: ProjectTeamMembersUpdatePathParams;
} & OneApiContext['fetcherOptions'];

export const fetchProjectTeamMembersUpdate = (
  variables: ProjectTeamMembersUpdateVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    Schemas.ProjectTeamMember,
    ProjectTeamMembersUpdateError,
    RequestBodies.ProjectTeamMember,
    {},
    {},
    ProjectTeamMembersUpdatePathParams
  >({ url: '/project-team-members/{id}', method: 'put', ...variables, signal });

export const useProjectTeamMembersUpdate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.ProjectTeamMember,
      ProjectTeamMembersUpdateError,
      ProjectTeamMembersUpdateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useOneApiContext();
  return reactQuery.useMutation<
    Schemas.ProjectTeamMember,
    ProjectTeamMembersUpdateError,
    ProjectTeamMembersUpdateVariables
  >(
    (variables: ProjectTeamMembersUpdateVariables) =>
      fetchProjectTeamMembersUpdate({ ...fetcherOptions, ...variables }),
    options
  );
};

export type ProjectTeamMembersDeletePathParams = {
  /**
   * A UUID string identifying this project team member.
   *
   * @format uuid
   */
  id: string;
};

export type ProjectTeamMembersDeleteError = Fetcher.ErrorWrapper<undefined>;

export type ProjectTeamMembersDeleteVariables = {
  pathParams: ProjectTeamMembersDeletePathParams;
} & OneApiContext['fetcherOptions'];

export const fetchProjectTeamMembersDelete = (
  variables: ProjectTeamMembersDeleteVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    undefined,
    ProjectTeamMembersDeleteError,
    undefined,
    {},
    {},
    ProjectTeamMembersDeletePathParams
  >({
    url: '/project-team-members/{id}',
    method: 'delete',
    ...variables,
    signal,
  });

export const useProjectTeamMembersDelete = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      ProjectTeamMembersDeleteError,
      ProjectTeamMembersDeleteVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useOneApiContext();
  return reactQuery.useMutation<
    undefined,
    ProjectTeamMembersDeleteError,
    ProjectTeamMembersDeleteVariables
  >(
    (variables: ProjectTeamMembersDeleteVariables) =>
      fetchProjectTeamMembersDelete({ ...fetcherOptions, ...variables }),
    options
  );
};

export type ProjectsListError = Fetcher.ErrorWrapper<undefined>;

export type ProjectsListResponse = Schemas.Project[];

export type ProjectsListVariables = OneApiContext['fetcherOptions'];

/**
 * Returns a list of all the projects the currently authenticated user is a team member of
 */
export const fetchProjectsList = (
  variables: ProjectsListVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<ProjectsListResponse, ProjectsListError, undefined, {}, {}, {}>({
    url: '/projects',
    method: 'get',
    ...variables,
    signal,
  });

/**
 * Returns a list of all the projects the currently authenticated user is a team member of
 */
export const useProjectsList = <TData = ProjectsListResponse>(
  variables: ProjectsListVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<ProjectsListResponse, ProjectsListError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<ProjectsListResponse, ProjectsListError, TData>(
    queryKeyFn({ path: '/projects', operationId: 'projectsList', variables }),
    ({ signal }) =>
      fetchProjectsList({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type ProjectsCreateError = Fetcher.ErrorWrapper<undefined>;

export type ProjectsCreateVariables = {
  body: Schemas.Project;
} & OneApiContext['fetcherOptions'];

/**
 * Creates a new project and adds the requesting user as project-admin
 */
export const fetchProjectsCreate = (
  variables: ProjectsCreateVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    Schemas.Project,
    ProjectsCreateError,
    Schemas.Project,
    {},
    {},
    {}
  >({ url: '/projects', method: 'post', ...variables, signal });

/**
 * Creates a new project and adds the requesting user as project-admin
 */
export const useProjectsCreate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.Project,
      ProjectsCreateError,
      ProjectsCreateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useOneApiContext();
  return reactQuery.useMutation<
    Schemas.Project,
    ProjectsCreateError,
    ProjectsCreateVariables
  >(
    (variables: ProjectsCreateVariables) =>
      fetchProjectsCreate({ ...fetcherOptions, ...variables }),
    options
  );
};

export type ProjectsArchiveProjectCreatePathParams = {
  projectPk: string;
  /**
   * Project ID
   */
  id: string;
};

export type ProjectsArchiveProjectCreateError = Fetcher.ErrorWrapper<undefined>;

export type ProjectsArchiveProjectCreateVariables = {
  pathParams: ProjectsArchiveProjectCreatePathParams;
} & OneApiContext['fetcherOptions'];

/**
 * Trigger actions that in order to ensure that the project is archived
 */
export const fetchProjectsArchiveProjectCreate = (
  variables: ProjectsArchiveProjectCreateVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    undefined,
    ProjectsArchiveProjectCreateError,
    undefined,
    {},
    {},
    ProjectsArchiveProjectCreatePathParams
  >({
    url: '/projects/archive-project/{projectPk}',
    method: 'post',
    ...variables,
    signal,
  });

/**
 * Trigger actions that in order to ensure that the project is archived
 */
export const useProjectsArchiveProjectCreate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      ProjectsArchiveProjectCreateError,
      ProjectsArchiveProjectCreateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useOneApiContext();
  return reactQuery.useMutation<
    undefined,
    ProjectsArchiveProjectCreateError,
    ProjectsArchiveProjectCreateVariables
  >(
    (variables: ProjectsArchiveProjectCreateVariables) =>
      fetchProjectsArchiveProjectCreate({ ...fetcherOptions, ...variables }),
    options
  );
};

export type ProjectsBulkUpdateMaturityCreatePathParams = {
  projectPk: string;
};

export type ProjectsBulkUpdateMaturityCreateError =
  Fetcher.ErrorWrapper<undefined>;

export type ProjectsBulkUpdateMaturityCreateResponse = {
  /**
   * IDs of topics to activate
   */
  activate_topics?: string[];
  /**
   * IDs of topics to deactivate
   */
  deactivate_topics?: string[];
  /**
   * IDs of topics to be deleted
   */
  delete_topics?: string[];
  /**
   * IDs of areas to be deleted
   */
  delete_areas?: string[];
  create_topics?: {
    id?: string;
    ident?: string;
    title?: string;
    description?: string;
    area_ids?: string[];
  }[];
  update_topics?: {
    id?: string;
    title?: string;
    description?: string;
  }[];
  create_areas?: {
    id?: string;
    ident?: string;
    title?: string;
    description?: string;
    group_id?: string;
  }[];
  update_areas?: {
    id?: string;
    title?: string;
    description?: string;
  }[];
};

export type ProjectsBulkUpdateMaturityCreateRequestBody = {
  /**
   * IDs of topics to activate
   */
  activate_topics?: string[];
  /**
   * IDs of topics to deactivate
   */
  deactivate_topics?: string[];
  /**
   * IDs of topics to be deleted
   */
  delete_topics?: string[];
  /**
   * IDs of areas to be deleted
   */
  delete_areas?: string[];
  create_topics?: {
    id?: string;
    ident?: string;
    title?: string;
    description?: string;
    area_ids?: string[];
  }[];
  update_topics?: {
    id?: string;
    title?: string;
    description?: string;
  }[];
  create_areas?: {
    id?: string;
    ident?: string;
    title?: string;
    description?: string;
    group_id?: string;
  }[];
  update_areas?: {
    id?: string;
    title?: string;
    description?: string;
  }[];
};

export type ProjectsBulkUpdateMaturityCreateVariables = {
  body?: ProjectsBulkUpdateMaturityCreateRequestBody;
  pathParams: ProjectsBulkUpdateMaturityCreatePathParams;
} & OneApiContext['fetcherOptions'];

export const fetchProjectsBulkUpdateMaturityCreate = (
  variables: ProjectsBulkUpdateMaturityCreateVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    ProjectsBulkUpdateMaturityCreateResponse,
    ProjectsBulkUpdateMaturityCreateError,
    ProjectsBulkUpdateMaturityCreateRequestBody,
    {},
    {},
    ProjectsBulkUpdateMaturityCreatePathParams
  >({
    url: '/projects/bulk-update-maturity/{projectPk}',
    method: 'post',
    ...variables,
    signal,
  });

export const useProjectsBulkUpdateMaturityCreate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      ProjectsBulkUpdateMaturityCreateResponse,
      ProjectsBulkUpdateMaturityCreateError,
      ProjectsBulkUpdateMaturityCreateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useOneApiContext();
  return reactQuery.useMutation<
    ProjectsBulkUpdateMaturityCreateResponse,
    ProjectsBulkUpdateMaturityCreateError,
    ProjectsBulkUpdateMaturityCreateVariables
  >(
    (variables: ProjectsBulkUpdateMaturityCreateVariables) =>
      fetchProjectsBulkUpdateMaturityCreate({
        ...fetcherOptions,
        ...variables,
      }),
    options
  );
};

export type ProjectsExcelExportReadPathParams = {
  projectPk: string;
};

export type ProjectsExcelExportReadError = Fetcher.ErrorWrapper<undefined>;

export type ProjectsExcelExportReadVariables = {
  pathParams: ProjectsExcelExportReadPathParams;
} & OneApiContext['fetcherOptions'];

/**
 * Export data about Contexts, Repositories, Teams and People as an Excel Workbook.
 */
export const fetchProjectsExcelExportRead = (
  variables: ProjectsExcelExportReadVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    undefined,
    ProjectsExcelExportReadError,
    undefined,
    {},
    {},
    ProjectsExcelExportReadPathParams
  >({
    url: '/projects/excel-export/{projectPk}',
    method: 'get',
    ...variables,
    signal,
  });

/**
 * Export data about Contexts, Repositories, Teams and People as an Excel Workbook.
 */
export const useProjectsExcelExportRead = <TData = undefined>(
  variables: ProjectsExcelExportReadVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<undefined, ProjectsExcelExportReadError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<undefined, ProjectsExcelExportReadError, TData>(
    queryKeyFn({
      path: '/projects/excel-export/{project_pk}',
      operationId: 'projectsExcelExportRead',
      variables,
    }),
    ({ signal }) =>
      fetchProjectsExcelExportRead({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type ProjectsMetadataOptionsClientIndustriesListError =
  Fetcher.ErrorWrapper<undefined>;

export type ProjectsMetadataOptionsClientIndustriesListResponse =
  Schemas.ProjectMetadata[];

export type ProjectsMetadataOptionsClientIndustriesListVariables =
  OneApiContext['fetcherOptions'];

export const fetchProjectsMetadataOptionsClientIndustriesList = (
  variables: ProjectsMetadataOptionsClientIndustriesListVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    ProjectsMetadataOptionsClientIndustriesListResponse,
    ProjectsMetadataOptionsClientIndustriesListError,
    undefined,
    {},
    {},
    {}
  >({
    url: '/projects/metadata-options/client-industries',
    method: 'get',
    ...variables,
    signal,
  });

export const useProjectsMetadataOptionsClientIndustriesList = <
  TData = ProjectsMetadataOptionsClientIndustriesListResponse
>(
  variables: ProjectsMetadataOptionsClientIndustriesListVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      ProjectsMetadataOptionsClientIndustriesListResponse,
      ProjectsMetadataOptionsClientIndustriesListError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<
    ProjectsMetadataOptionsClientIndustriesListResponse,
    ProjectsMetadataOptionsClientIndustriesListError,
    TData
  >(
    queryKeyFn({
      path: '/projects/metadata-options/client-industries',
      operationId: 'projectsMetadataOptionsClientIndustriesList',
      variables,
    }),
    ({ signal }) =>
      fetchProjectsMetadataOptionsClientIndustriesList(
        { ...fetcherOptions, ...variables },
        signal
      ),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type ProjectsMetadataOptionsIndustriesListError =
  Fetcher.ErrorWrapper<undefined>;

export type ProjectsMetadataOptionsIndustriesListResponse =
  Schemas.ProjectMetadata[];

export type ProjectsMetadataOptionsIndustriesListVariables =
  OneApiContext['fetcherOptions'];

export const fetchProjectsMetadataOptionsIndustriesList = (
  variables: ProjectsMetadataOptionsIndustriesListVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    ProjectsMetadataOptionsIndustriesListResponse,
    ProjectsMetadataOptionsIndustriesListError,
    undefined,
    {},
    {},
    {}
  >({
    url: '/projects/metadata-options/industries',
    method: 'get',
    ...variables,
    signal,
  });

export const useProjectsMetadataOptionsIndustriesList = <
  TData = ProjectsMetadataOptionsIndustriesListResponse
>(
  variables: ProjectsMetadataOptionsIndustriesListVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      ProjectsMetadataOptionsIndustriesListResponse,
      ProjectsMetadataOptionsIndustriesListError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<
    ProjectsMetadataOptionsIndustriesListResponse,
    ProjectsMetadataOptionsIndustriesListError,
    TData
  >(
    queryKeyFn({
      path: '/projects/metadata-options/industries',
      operationId: 'projectsMetadataOptionsIndustriesList',
      variables,
    }),
    ({ signal }) =>
      fetchProjectsMetadataOptionsIndustriesList(
        { ...fetcherOptions, ...variables },
        signal
      ),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type ProjectsMetadataOptionsProjectTypesListError =
  Fetcher.ErrorWrapper<undefined>;

export type ProjectsMetadataOptionsProjectTypesListResponse =
  Schemas.ProjectMetadata[];

export type ProjectsMetadataOptionsProjectTypesListVariables =
  OneApiContext['fetcherOptions'];

export const fetchProjectsMetadataOptionsProjectTypesList = (
  variables: ProjectsMetadataOptionsProjectTypesListVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    ProjectsMetadataOptionsProjectTypesListResponse,
    ProjectsMetadataOptionsProjectTypesListError,
    undefined,
    {},
    {},
    {}
  >({
    url: '/projects/metadata-options/project-types',
    method: 'get',
    ...variables,
    signal,
  });

export const useProjectsMetadataOptionsProjectTypesList = <
  TData = ProjectsMetadataOptionsProjectTypesListResponse
>(
  variables: ProjectsMetadataOptionsProjectTypesListVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      ProjectsMetadataOptionsProjectTypesListResponse,
      ProjectsMetadataOptionsProjectTypesListError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<
    ProjectsMetadataOptionsProjectTypesListResponse,
    ProjectsMetadataOptionsProjectTypesListError,
    TData
  >(
    queryKeyFn({
      path: '/projects/metadata-options/project-types',
      operationId: 'projectsMetadataOptionsProjectTypesList',
      variables,
    }),
    ({ signal }) =>
      fetchProjectsMetadataOptionsProjectTypesList(
        { ...fetcherOptions, ...variables },
        signal
      ),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type ProjectsMetadataOptionsSizesListError =
  Fetcher.ErrorWrapper<undefined>;

export type ProjectsMetadataOptionsSizesListResponse =
  Schemas.ProjectMetadata[];

export type ProjectsMetadataOptionsSizesListVariables =
  OneApiContext['fetcherOptions'];

export const fetchProjectsMetadataOptionsSizesList = (
  variables: ProjectsMetadataOptionsSizesListVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    ProjectsMetadataOptionsSizesListResponse,
    ProjectsMetadataOptionsSizesListError,
    undefined,
    {},
    {},
    {}
  >({
    url: '/projects/metadata-options/sizes',
    method: 'get',
    ...variables,
    signal,
  });

export const useProjectsMetadataOptionsSizesList = <
  TData = ProjectsMetadataOptionsSizesListResponse
>(
  variables: ProjectsMetadataOptionsSizesListVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      ProjectsMetadataOptionsSizesListResponse,
      ProjectsMetadataOptionsSizesListError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<
    ProjectsMetadataOptionsSizesListResponse,
    ProjectsMetadataOptionsSizesListError,
    TData
  >(
    queryKeyFn({
      path: '/projects/metadata-options/sizes',
      operationId: 'projectsMetadataOptionsSizesList',
      variables,
    }),
    ({ signal }) =>
      fetchProjectsMetadataOptionsSizesList(
        { ...fetcherOptions, ...variables },
        signal
      ),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type ProjectsMetadataOptionsStagesListError =
  Fetcher.ErrorWrapper<undefined>;

export type ProjectsMetadataOptionsStagesListResponse =
  Schemas.ProjectMetadata[];

export type ProjectsMetadataOptionsStagesListVariables =
  OneApiContext['fetcherOptions'];

export const fetchProjectsMetadataOptionsStagesList = (
  variables: ProjectsMetadataOptionsStagesListVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    ProjectsMetadataOptionsStagesListResponse,
    ProjectsMetadataOptionsStagesListError,
    undefined,
    {},
    {},
    {}
  >({
    url: '/projects/metadata-options/stages',
    method: 'get',
    ...variables,
    signal,
  });

export const useProjectsMetadataOptionsStagesList = <
  TData = ProjectsMetadataOptionsStagesListResponse
>(
  variables: ProjectsMetadataOptionsStagesListVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      ProjectsMetadataOptionsStagesListResponse,
      ProjectsMetadataOptionsStagesListError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<
    ProjectsMetadataOptionsStagesListResponse,
    ProjectsMetadataOptionsStagesListError,
    TData
  >(
    queryKeyFn({
      path: '/projects/metadata-options/stages',
      operationId: 'projectsMetadataOptionsStagesList',
      variables,
    }),
    ({ signal }) =>
      fetchProjectsMetadataOptionsStagesList(
        { ...fetcherOptions, ...variables },
        signal
      ),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type ProjectsReadPathParams = {
  /**
   * A UUID string identifying this project.
   *
   * @format uuid
   */
  id: string;
};

export type ProjectsReadError = Fetcher.ErrorWrapper<undefined>;

export type ProjectsReadVariables = {
  pathParams: ProjectsReadPathParams;
} & OneApiContext['fetcherOptions'];

export const fetchProjectsRead = (
  variables: ProjectsReadVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    Schemas.ProjectDetail,
    ProjectsReadError,
    undefined,
    {},
    {},
    ProjectsReadPathParams
  >({ url: '/projects/{id}', method: 'get', ...variables, signal });

export const useProjectsRead = <TData = Schemas.ProjectDetail>(
  variables: ProjectsReadVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.ProjectDetail, ProjectsReadError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<Schemas.ProjectDetail, ProjectsReadError, TData>(
    queryKeyFn({
      path: '/projects/{id}',
      operationId: 'projectsRead',
      variables,
    }),
    ({ signal }) =>
      fetchProjectsRead({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type ProjectsUpdatePathParams = {
  /**
   * A UUID string identifying this project.
   *
   * @format uuid
   */
  id: string;
};

export type ProjectsUpdateError = Fetcher.ErrorWrapper<undefined>;

export type ProjectsUpdateVariables = {
  body: Schemas.ProjectDetail;
  pathParams: ProjectsUpdatePathParams;
} & OneApiContext['fetcherOptions'];

export const fetchProjectsUpdate = (
  variables: ProjectsUpdateVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    Schemas.ProjectDetail,
    ProjectsUpdateError,
    Schemas.ProjectDetail,
    {},
    {},
    ProjectsUpdatePathParams
  >({ url: '/projects/{id}', method: 'put', ...variables, signal });

export const useProjectsUpdate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.ProjectDetail,
      ProjectsUpdateError,
      ProjectsUpdateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useOneApiContext();
  return reactQuery.useMutation<
    Schemas.ProjectDetail,
    ProjectsUpdateError,
    ProjectsUpdateVariables
  >(
    (variables: ProjectsUpdateVariables) =>
      fetchProjectsUpdate({ ...fetcherOptions, ...variables }),
    options
  );
};

export type ProjectsDeletePathParams = {
  /**
   * A UUID string identifying this project.
   *
   * @format uuid
   */
  id: string;
};

export type ProjectsDeleteError = Fetcher.ErrorWrapper<undefined>;

export type ProjectsDeleteVariables = {
  pathParams: ProjectsDeletePathParams;
} & OneApiContext['fetcherOptions'];

export const fetchProjectsDelete = (
  variables: ProjectsDeleteVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    undefined,
    ProjectsDeleteError,
    undefined,
    {},
    {},
    ProjectsDeletePathParams
  >({ url: '/projects/{id}', method: 'delete', ...variables, signal });

export const useProjectsDelete = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      ProjectsDeleteError,
      ProjectsDeleteVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useOneApiContext();
  return reactQuery.useMutation<
    undefined,
    ProjectsDeleteError,
    ProjectsDeleteVariables
  >(
    (variables: ProjectsDeleteVariables) =>
      fetchProjectsDelete({ ...fetcherOptions, ...variables }),
    options
  );
};

export type ProjectsDashboardUrlListPathParams = {
  id: string;
};

export type ProjectsDashboardUrlListError = Fetcher.ErrorWrapper<undefined>;

export type ProjectsDashboardUrlListVariables = {
  pathParams: ProjectsDashboardUrlListPathParams;
} & OneApiContext['fetcherOptions'];

export const fetchProjectsDashboardUrlList = (
  variables: ProjectsDashboardUrlListVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    undefined,
    ProjectsDashboardUrlListError,
    undefined,
    {},
    {},
    ProjectsDashboardUrlListPathParams
  >({
    url: '/projects/{id}/dashboard-url',
    method: 'get',
    ...variables,
    signal,
  });

export const useProjectsDashboardUrlList = <TData = undefined>(
  variables: ProjectsDashboardUrlListVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<undefined, ProjectsDashboardUrlListError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<undefined, ProjectsDashboardUrlListError, TData>(
    queryKeyFn({
      path: '/projects/{id}/dashboard-url',
      operationId: 'projectsDashboardUrlList',
      variables,
    }),
    ({ signal }) =>
      fetchProjectsDashboardUrlList(
        { ...fetcherOptions, ...variables },
        signal
      ),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type ProjectsDirectoryCreatePathParams = {
  /**
   * Project ID
   */
  id: string;
};

export type ProjectsDirectoryCreateError = Fetcher.ErrorWrapper<undefined>;

export type ProjectsDirectoryCreateVariables = {
  pathParams: ProjectsDirectoryCreatePathParams;
} & OneApiContext['fetcherOptions'];

/**
 * Request body example:
 *     {"path": "some/sub/path/"}
 */
export const fetchProjectsDirectoryCreate = (
  variables: ProjectsDirectoryCreateVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    undefined,
    ProjectsDirectoryCreateError,
    undefined,
    {},
    {},
    ProjectsDirectoryCreatePathParams
  >({ url: '/projects/{id}/directory', method: 'post', ...variables, signal });

/**
 * Request body example:
 *     {"path": "some/sub/path/"}
 */
export const useProjectsDirectoryCreate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      ProjectsDirectoryCreateError,
      ProjectsDirectoryCreateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useOneApiContext();
  return reactQuery.useMutation<
    undefined,
    ProjectsDirectoryCreateError,
    ProjectsDirectoryCreateVariables
  >(
    (variables: ProjectsDirectoryCreateVariables) =>
      fetchProjectsDirectoryCreate({ ...fetcherOptions, ...variables }),
    options
  );
};

export type ProjectsExportMaturityListPathParams = {
  /**
   * A UUID string identifying this project.
   *
   * @format uuid
   */
  id: string;
};

export type ProjectsExportMaturityListError = Fetcher.ErrorWrapper<undefined>;

export type ProjectsExportMaturityListVariables = {
  pathParams: ProjectsExportMaturityListPathParams;
} & OneApiContext['fetcherOptions'];

export const fetchProjectsExportMaturityList = (
  variables: ProjectsExportMaturityListVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    undefined,
    ProjectsExportMaturityListError,
    undefined,
    {},
    {},
    ProjectsExportMaturityListPathParams
  >({
    url: '/projects/{id}/export-maturity',
    method: 'get',
    ...variables,
    signal,
  });

export const useProjectsExportMaturityList = <TData = undefined>(
  variables: ProjectsExportMaturityListVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      undefined,
      ProjectsExportMaturityListError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<undefined, ProjectsExportMaturityListError, TData>(
    queryKeyFn({
      path: '/projects/{id}/export-maturity',
      operationId: 'projectsExportMaturityList',
      variables,
    }),
    ({ signal }) =>
      fetchProjectsExportMaturityList(
        { ...fetcherOptions, ...variables },
        signal
      ),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type ProjectsFilesListPathParams = {
  /**
   * Project ID
   */
  id: string;
};

export type ProjectsFilesListError = Fetcher.ErrorWrapper<undefined>;

export type ProjectsFilesListResponse = Schemas.FilesItemResponse[];

export type ProjectsFilesListVariables = {
  pathParams: ProjectsFilesListPathParams;
} & OneApiContext['fetcherOptions'];

/**
 * retrieves the given project's files and directories. A filter can be passed optionally as request body (which
 *     is also the reason why this API expects POST and not GET).
 *
 *     Request body example:
 *     {"filter":
 *         {"path": "some/sub/path/"}
 *     }
 */
export const fetchProjectsFilesList = (
  variables: ProjectsFilesListVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    ProjectsFilesListResponse,
    ProjectsFilesListError,
    undefined,
    {},
    {},
    ProjectsFilesListPathParams
  >({ url: '/projects/{id}/files', method: 'post', ...variables, signal });

/**
 * retrieves the given project's files and directories. A filter can be passed optionally as request body (which
 *     is also the reason why this API expects POST and not GET).
 *
 *     Request body example:
 *     {"filter":
 *         {"path": "some/sub/path/"}
 *     }
 */
export const useProjectsFilesList = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      ProjectsFilesListResponse,
      ProjectsFilesListError,
      ProjectsFilesListVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useOneApiContext();
  return reactQuery.useMutation<
    ProjectsFilesListResponse,
    ProjectsFilesListError,
    ProjectsFilesListVariables
  >(
    (variables: ProjectsFilesListVariables) =>
      fetchProjectsFilesList({ ...fetcherOptions, ...variables }),
    options
  );
};

export type ProjectsFilesReadPathParams = {
  /**
   * Project ID
   */
  id: string;
  /**
   * urlsafe-base64 encoded file path
   */
  pathB64: string;
};

export type ProjectsFilesReadError = Fetcher.ErrorWrapper<undefined>;

export type ProjectsFilesReadVariables = {
  pathParams: ProjectsFilesReadPathParams;
} & OneApiContext['fetcherOptions'];

/**
 * responds with the content of the requested file
 */
export const fetchProjectsFilesRead = (
  variables: ProjectsFilesReadVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    undefined,
    ProjectsFilesReadError,
    undefined,
    {},
    {},
    ProjectsFilesReadPathParams
  >({
    url: '/projects/{id}/files/{pathB64}',
    method: 'get',
    ...variables,
    signal,
  });

/**
 * responds with the content of the requested file
 */
export const useProjectsFilesRead = <TData = undefined>(
  variables: ProjectsFilesReadVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<undefined, ProjectsFilesReadError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<undefined, ProjectsFilesReadError, TData>(
    queryKeyFn({
      path: '/projects/{id}/files/{path_b64}',
      operationId: 'projectsFilesRead',
      variables,
    }),
    ({ signal }) =>
      fetchProjectsFilesRead({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type ProjectsFilesCreatePathParams = {
  /**
   * Project ID
   */
  id: string;
  /**
   * urlsafe-base64 encoded file path
   */
  pathB64: string;
};

export type ProjectsFilesCreateError = Fetcher.ErrorWrapper<undefined>;

export type ProjectsFilesCreateVariables = {
  pathParams: ProjectsFilesCreatePathParams;
} & OneApiContext['fetcherOptions'];

/**
 * creates new file
 */
export const fetchProjectsFilesCreate = (
  variables: ProjectsFilesCreateVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    undefined,
    ProjectsFilesCreateError,
    undefined,
    {},
    {},
    ProjectsFilesCreatePathParams
  >({
    url: '/projects/{id}/files/{pathB64}',
    method: 'post',
    ...variables,
    signal,
  });

/**
 * creates new file
 */
export const useProjectsFilesCreate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      ProjectsFilesCreateError,
      ProjectsFilesCreateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useOneApiContext();
  return reactQuery.useMutation<
    undefined,
    ProjectsFilesCreateError,
    ProjectsFilesCreateVariables
  >(
    (variables: ProjectsFilesCreateVariables) =>
      fetchProjectsFilesCreate({ ...fetcherOptions, ...variables }),
    options
  );
};

export type ProjectsFindingAreasListPathParams = {
  id: string;
};

export type ProjectsFindingAreasListQueryParams = {
  /**
   * Shows only FindingAreas with Findings
   */
  onlyWithFindings?: boolean;
};

export type ProjectsFindingAreasListError = Fetcher.ErrorWrapper<undefined>;

export type ProjectsFindingAreasListVariables = {
  pathParams: ProjectsFindingAreasListPathParams;
  queryParams?: ProjectsFindingAreasListQueryParams;
} & OneApiContext['fetcherOptions'];

export const fetchProjectsFindingAreasList = (
  variables: ProjectsFindingAreasListVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    undefined,
    ProjectsFindingAreasListError,
    undefined,
    {},
    ProjectsFindingAreasListQueryParams,
    ProjectsFindingAreasListPathParams
  >({
    url: '/projects/{id}/finding-areas',
    method: 'get',
    ...variables,
    signal,
  });

export const useProjectsFindingAreasList = <TData = undefined>(
  variables: ProjectsFindingAreasListVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<undefined, ProjectsFindingAreasListError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<undefined, ProjectsFindingAreasListError, TData>(
    queryKeyFn({
      path: '/projects/{id}/finding-areas',
      operationId: 'projectsFindingAreasList',
      variables,
    }),
    ({ signal }) =>
      fetchProjectsFindingAreasList(
        { ...fetcherOptions, ...variables },
        signal
      ),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type ProjectsFindingGroupsListPathParams = {
  id: string;
};

export type ProjectsFindingGroupsListError = Fetcher.ErrorWrapper<undefined>;

export type ProjectsFindingGroupsListResponse = Schemas.FindingGroup[];

export type ProjectsFindingGroupsListVariables = {
  pathParams: ProjectsFindingGroupsListPathParams;
} & OneApiContext['fetcherOptions'];

export const fetchProjectsFindingGroupsList = (
  variables: ProjectsFindingGroupsListVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    ProjectsFindingGroupsListResponse,
    ProjectsFindingGroupsListError,
    undefined,
    {},
    {},
    ProjectsFindingGroupsListPathParams
  >({
    url: '/projects/{id}/finding-groups',
    method: 'get',
    ...variables,
    signal,
  });

export const useProjectsFindingGroupsList = <
  TData = ProjectsFindingGroupsListResponse
>(
  variables: ProjectsFindingGroupsListVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      ProjectsFindingGroupsListResponse,
      ProjectsFindingGroupsListError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<
    ProjectsFindingGroupsListResponse,
    ProjectsFindingGroupsListError,
    TData
  >(
    queryKeyFn({
      path: '/projects/{id}/finding-groups',
      operationId: 'projectsFindingGroupsList',
      variables,
    }),
    ({ signal }) =>
      fetchProjectsFindingGroupsList(
        { ...fetcherOptions, ...variables },
        signal
      ),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type ProjectsFindingGroupsRatingListPathParams = {
  id: string;
};

export type ProjectsFindingGroupsRatingListError =
  Fetcher.ErrorWrapper<undefined>;

export type ProjectsFindingGroupsRatingListVariables = {
  pathParams: ProjectsFindingGroupsRatingListPathParams;
} & OneApiContext['fetcherOptions'];

export const fetchProjectsFindingGroupsRatingList = (
  variables: ProjectsFindingGroupsRatingListVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    undefined,
    ProjectsFindingGroupsRatingListError,
    undefined,
    {},
    {},
    ProjectsFindingGroupsRatingListPathParams
  >({
    url: '/projects/{id}/finding-groups/rating',
    method: 'get',
    ...variables,
    signal,
  });

export const useProjectsFindingGroupsRatingList = <TData = undefined>(
  variables: ProjectsFindingGroupsRatingListVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      undefined,
      ProjectsFindingGroupsRatingListError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<
    undefined,
    ProjectsFindingGroupsRatingListError,
    TData
  >(
    queryKeyFn({
      path: '/projects/{id}/finding-groups/rating',
      operationId: 'projectsFindingGroupsRatingList',
      variables,
    }),
    ({ signal }) =>
      fetchProjectsFindingGroupsRatingList(
        { ...fetcherOptions, ...variables },
        signal
      ),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type ProjectsFindingsListPathParams = {
  id: string;
};

export type ProjectsFindingsListError = Fetcher.ErrorWrapper<undefined>;

export type ProjectsFindingsListResponse = Schemas.Finding[];

export type ProjectsFindingsListVariables = {
  pathParams: ProjectsFindingsListPathParams;
} & OneApiContext['fetcherOptions'];

export const fetchProjectsFindingsList = (
  variables: ProjectsFindingsListVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    ProjectsFindingsListResponse,
    ProjectsFindingsListError,
    undefined,
    {},
    {},
    ProjectsFindingsListPathParams
  >({ url: '/projects/{id}/findings', method: 'get', ...variables, signal });

export const useProjectsFindingsList = <TData = ProjectsFindingsListResponse>(
  variables: ProjectsFindingsListVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      ProjectsFindingsListResponse,
      ProjectsFindingsListError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<
    ProjectsFindingsListResponse,
    ProjectsFindingsListError,
    TData
  >(
    queryKeyFn({
      path: '/projects/{id}/findings',
      operationId: 'projectsFindingsList',
      variables,
    }),
    ({ signal }) =>
      fetchProjectsFindingsList({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type ProjectsFindingsStrengthsListPathParams = {
  id: string;
};

export type ProjectsFindingsStrengthsListError =
  Fetcher.ErrorWrapper<undefined>;

export type ProjectsFindingsStrengthsListResponse = Schemas.Finding[];

export type ProjectsFindingsStrengthsListVariables = {
  pathParams: ProjectsFindingsStrengthsListPathParams;
} & OneApiContext['fetcherOptions'];

export const fetchProjectsFindingsStrengthsList = (
  variables: ProjectsFindingsStrengthsListVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    ProjectsFindingsStrengthsListResponse,
    ProjectsFindingsStrengthsListError,
    undefined,
    {},
    {},
    ProjectsFindingsStrengthsListPathParams
  >({
    url: '/projects/{id}/findings/strengths',
    method: 'get',
    ...variables,
    signal,
  });

export const useProjectsFindingsStrengthsList = <
  TData = ProjectsFindingsStrengthsListResponse
>(
  variables: ProjectsFindingsStrengthsListVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      ProjectsFindingsStrengthsListResponse,
      ProjectsFindingsStrengthsListError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<
    ProjectsFindingsStrengthsListResponse,
    ProjectsFindingsStrengthsListError,
    TData
  >(
    queryKeyFn({
      path: '/projects/{id}/findings/strengths',
      operationId: 'projectsFindingsStrengthsList',
      variables,
    }),
    ({ signal }) =>
      fetchProjectsFindingsStrengthsList(
        { ...fetcherOptions, ...variables },
        signal
      ),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type ProjectsFindingsWeaknessesListPathParams = {
  id: string;
};

export type ProjectsFindingsWeaknessesListError =
  Fetcher.ErrorWrapper<undefined>;

export type ProjectsFindingsWeaknessesListResponse = Schemas.Finding[];

export type ProjectsFindingsWeaknessesListVariables = {
  pathParams: ProjectsFindingsWeaknessesListPathParams;
} & OneApiContext['fetcherOptions'];

export const fetchProjectsFindingsWeaknessesList = (
  variables: ProjectsFindingsWeaknessesListVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    ProjectsFindingsWeaknessesListResponse,
    ProjectsFindingsWeaknessesListError,
    undefined,
    {},
    {},
    ProjectsFindingsWeaknessesListPathParams
  >({
    url: '/projects/{id}/findings/weaknesses',
    method: 'get',
    ...variables,
    signal,
  });

export const useProjectsFindingsWeaknessesList = <
  TData = ProjectsFindingsWeaknessesListResponse
>(
  variables: ProjectsFindingsWeaknessesListVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      ProjectsFindingsWeaknessesListResponse,
      ProjectsFindingsWeaknessesListError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<
    ProjectsFindingsWeaknessesListResponse,
    ProjectsFindingsWeaknessesListError,
    TData
  >(
    queryKeyFn({
      path: '/projects/{id}/findings/weaknesses',
      operationId: 'projectsFindingsWeaknessesList',
      variables,
    }),
    ({ signal }) =>
      fetchProjectsFindingsWeaknessesList(
        { ...fetcherOptions, ...variables },
        signal
      ),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type ProjectsMaturityBulkReadPathParams = {
  /**
   * A UUID string identifying this project.
   *
   * @format uuid
   */
  id: string;
};

export type ProjectsMaturityBulkReadError = Fetcher.ErrorWrapper<undefined>;

export type ProjectsMaturityBulkReadVariables = {
  pathParams: ProjectsMaturityBulkReadPathParams;
} & OneApiContext['fetcherOptions'];

export const fetchProjectsMaturityBulkRead = (
  variables: ProjectsMaturityBulkReadVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    Schemas.MaturityProject,
    ProjectsMaturityBulkReadError,
    undefined,
    {},
    {},
    ProjectsMaturityBulkReadPathParams
  >({
    url: '/projects/{id}/maturity-bulk',
    method: 'get',
    ...variables,
    signal,
  });

export const useProjectsMaturityBulkRead = <TData = Schemas.MaturityProject>(
  variables: ProjectsMaturityBulkReadVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.MaturityProject,
      ProjectsMaturityBulkReadError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<
    Schemas.MaturityProject,
    ProjectsMaturityBulkReadError,
    TData
  >(
    queryKeyFn({
      path: '/projects/{id}/maturity-bulk',
      operationId: 'projectsMaturityBulkRead',
      variables,
    }),
    ({ signal }) =>
      fetchProjectsMaturityBulkRead(
        { ...fetcherOptions, ...variables },
        signal
      ),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type ProjectsMaturityViewsListPathParams = {
  id: string;
};

export type ProjectsMaturityViewsListError = Fetcher.ErrorWrapper<undefined>;

export type ProjectsMaturityViewsListResponse = Schemas.MaturityView[];

export type ProjectsMaturityViewsListVariables = {
  pathParams: ProjectsMaturityViewsListPathParams;
} & OneApiContext['fetcherOptions'];

export const fetchProjectsMaturityViewsList = (
  variables: ProjectsMaturityViewsListVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    ProjectsMaturityViewsListResponse,
    ProjectsMaturityViewsListError,
    undefined,
    {},
    {},
    ProjectsMaturityViewsListPathParams
  >({
    url: '/projects/{id}/maturity-views',
    method: 'get',
    ...variables,
    signal,
  });

export const useProjectsMaturityViewsList = <
  TData = ProjectsMaturityViewsListResponse
>(
  variables: ProjectsMaturityViewsListVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      ProjectsMaturityViewsListResponse,
      ProjectsMaturityViewsListError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<
    ProjectsMaturityViewsListResponse,
    ProjectsMaturityViewsListError,
    TData
  >(
    queryKeyFn({
      path: '/projects/{id}/maturity-views',
      operationId: 'projectsMaturityViewsList',
      variables,
    }),
    ({ signal }) =>
      fetchProjectsMaturityViewsList(
        { ...fetcherOptions, ...variables },
        signal
      ),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type ProjectsMergedSanityCheckListPathParams = {
  id: string;
};

export type ProjectsMergedSanityCheckListError =
  Fetcher.ErrorWrapper<undefined>;

export type ProjectsMergedSanityCheckListResponse = Schemas.SanityCheckResult[];

export type ProjectsMergedSanityCheckListVariables = {
  pathParams: ProjectsMergedSanityCheckListPathParams;
} & OneApiContext['fetcherOptions'];

/**
 * Merge result of sanity check results from S3 and the database.
 * If there are results for the same ident from both sources,
 * the result from the database overrides the results from S3
 */
export const fetchProjectsMergedSanityCheckList = (
  variables: ProjectsMergedSanityCheckListVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    ProjectsMergedSanityCheckListResponse,
    ProjectsMergedSanityCheckListError,
    undefined,
    {},
    {},
    ProjectsMergedSanityCheckListPathParams
  >({
    url: '/projects/{id}/merged-sanity-check',
    method: 'get',
    ...variables,
    signal,
  });

/**
 * Merge result of sanity check results from S3 and the database.
 * If there are results for the same ident from both sources,
 * the result from the database overrides the results from S3
 */
export const useProjectsMergedSanityCheckList = <
  TData = ProjectsMergedSanityCheckListResponse
>(
  variables: ProjectsMergedSanityCheckListVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      ProjectsMergedSanityCheckListResponse,
      ProjectsMergedSanityCheckListError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<
    ProjectsMergedSanityCheckListResponse,
    ProjectsMergedSanityCheckListError,
    TData
  >(
    queryKeyFn({
      path: '/projects/{id}/merged-sanity-check',
      operationId: 'projectsMergedSanityCheckList',
      variables,
    }),
    ({ signal }) =>
      fetchProjectsMergedSanityCheckList(
        { ...fetcherOptions, ...variables },
        signal
      ),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type ProjectsRelevantQuestionsListPathParams = {
  id: string;
};

export type ProjectsRelevantQuestionsListError =
  Fetcher.ErrorWrapper<undefined>;

export type ProjectsRelevantQuestionsListResponse = string[];

export type ProjectsRelevantQuestionsListVariables = {
  pathParams: ProjectsRelevantQuestionsListPathParams;
} & OneApiContext['fetcherOptions'];

export const fetchProjectsRelevantQuestionsList = (
  variables: ProjectsRelevantQuestionsListVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    ProjectsRelevantQuestionsListResponse,
    ProjectsRelevantQuestionsListError,
    undefined,
    {},
    {},
    ProjectsRelevantQuestionsListPathParams
  >({
    url: '/projects/{id}/relevant-questions',
    method: 'get',
    ...variables,
    signal,
  });

export const useProjectsRelevantQuestionsList = <
  TData = ProjectsRelevantQuestionsListResponse
>(
  variables: ProjectsRelevantQuestionsListVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      ProjectsRelevantQuestionsListResponse,
      ProjectsRelevantQuestionsListError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<
    ProjectsRelevantQuestionsListResponse,
    ProjectsRelevantQuestionsListError,
    TData
  >(
    queryKeyFn({
      path: '/projects/{id}/relevant-questions',
      operationId: 'projectsRelevantQuestionsList',
      variables,
    }),
    ({ signal }) =>
      fetchProjectsRelevantQuestionsList(
        { ...fetcherOptions, ...variables },
        signal
      ),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type ProjectsReportingChartsListPathParams = {
  id: string;
};

export type ProjectsReportingChartsListError = Fetcher.ErrorWrapper<undefined>;

export type ProjectsReportingChartsListVariables = {
  pathParams: ProjectsReportingChartsListPathParams;
} & OneApiContext['fetcherOptions'];

export const fetchProjectsReportingChartsList = (
  variables: ProjectsReportingChartsListVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    undefined,
    ProjectsReportingChartsListError,
    undefined,
    {},
    {},
    ProjectsReportingChartsListPathParams
  >({
    url: '/projects/{id}/reporting-charts',
    method: 'get',
    ...variables,
    signal,
  });

export const useProjectsReportingChartsList = <TData = undefined>(
  variables: ProjectsReportingChartsListVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      undefined,
      ProjectsReportingChartsListError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<
    undefined,
    ProjectsReportingChartsListError,
    TData
  >(
    queryKeyFn({
      path: '/projects/{id}/reporting-charts',
      operationId: 'projectsReportingChartsList',
      variables,
    }),
    ({ signal }) =>
      fetchProjectsReportingChartsList(
        { ...fetcherOptions, ...variables },
        signal
      ),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type ProjectsSanityCheckResultsCreatePathParams = {
  id: string;
};

export type ProjectsSanityCheckResultsCreateQueryParams = {
  /**
   * It deletes the existing SanityCheckResult of given project AND ident.
   */
  overwriteExisting?: boolean;
};

export type ProjectsSanityCheckResultsCreateError =
  Fetcher.ErrorWrapper<undefined>;

export type ProjectsSanityCheckResultsCreateVariables = {
  body: Schemas.SanityCheckResult;
  pathParams: ProjectsSanityCheckResultsCreatePathParams;
  queryParams?: ProjectsSanityCheckResultsCreateQueryParams;
} & OneApiContext['fetcherOptions'];

/**
 * Creates a SanityCheckResult of type MANUAL by given project and ident.
 */
export const fetchProjectsSanityCheckResultsCreate = (
  variables: ProjectsSanityCheckResultsCreateVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    Schemas.SanityCheckResult,
    ProjectsSanityCheckResultsCreateError,
    Schemas.SanityCheckResult,
    {},
    ProjectsSanityCheckResultsCreateQueryParams,
    ProjectsSanityCheckResultsCreatePathParams
  >({
    url: '/projects/{id}/sanity-check-results',
    method: 'post',
    ...variables,
    signal,
  });

/**
 * Creates a SanityCheckResult of type MANUAL by given project and ident.
 */
export const useProjectsSanityCheckResultsCreate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.SanityCheckResult,
      ProjectsSanityCheckResultsCreateError,
      ProjectsSanityCheckResultsCreateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useOneApiContext();
  return reactQuery.useMutation<
    Schemas.SanityCheckResult,
    ProjectsSanityCheckResultsCreateError,
    ProjectsSanityCheckResultsCreateVariables
  >(
    (variables: ProjectsSanityCheckResultsCreateVariables) =>
      fetchProjectsSanityCheckResultsCreate({
        ...fetcherOptions,
        ...variables,
      }),
    options
  );
};

export type ProjectsServiceModulesListPathParams = {
  id: string;
};

export type ProjectsServiceModulesListError = Fetcher.ErrorWrapper<undefined>;

export type ProjectsServiceModulesListResponse = Schemas.ServiceModule[];

export type ProjectsServiceModulesListVariables = {
  pathParams: ProjectsServiceModulesListPathParams;
} & OneApiContext['fetcherOptions'];

export const fetchProjectsServiceModulesList = (
  variables: ProjectsServiceModulesListVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    ProjectsServiceModulesListResponse,
    ProjectsServiceModulesListError,
    undefined,
    {},
    {},
    ProjectsServiceModulesListPathParams
  >({
    url: '/projects/{id}/service-modules',
    method: 'get',
    ...variables,
    signal,
  });

export const useProjectsServiceModulesList = <
  TData = ProjectsServiceModulesListResponse
>(
  variables: ProjectsServiceModulesListVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      ProjectsServiceModulesListResponse,
      ProjectsServiceModulesListError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<
    ProjectsServiceModulesListResponse,
    ProjectsServiceModulesListError,
    TData
  >(
    queryKeyFn({
      path: '/projects/{id}/service-modules',
      operationId: 'projectsServiceModulesList',
      variables,
    }),
    ({ signal }) =>
      fetchProjectsServiceModulesList(
        { ...fetcherOptions, ...variables },
        signal
      ),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type ProjectsTeamMembersListPathParams = {
  id: string;
};

export type ProjectsTeamMembersListError = Fetcher.ErrorWrapper<undefined>;

export type ProjectsTeamMembersListResponse = Schemas.ProjectTeamMember[];

export type ProjectsTeamMembersListVariables = {
  pathParams: ProjectsTeamMembersListPathParams;
} & OneApiContext['fetcherOptions'];

/**
 * List all existing team-members by project id.
 */
export const fetchProjectsTeamMembersList = (
  variables: ProjectsTeamMembersListVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    ProjectsTeamMembersListResponse,
    ProjectsTeamMembersListError,
    undefined,
    {},
    {},
    ProjectsTeamMembersListPathParams
  >({
    url: '/projects/{id}/team-members',
    method: 'get',
    ...variables,
    signal,
  });

/**
 * List all existing team-members by project id.
 */
export const useProjectsTeamMembersList = <
  TData = ProjectsTeamMembersListResponse
>(
  variables: ProjectsTeamMembersListVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      ProjectsTeamMembersListResponse,
      ProjectsTeamMembersListError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<
    ProjectsTeamMembersListResponse,
    ProjectsTeamMembersListError,
    TData
  >(
    queryKeyFn({
      path: '/projects/{id}/team-members',
      operationId: 'projectsTeamMembersList',
      variables,
    }),
    ({ signal }) =>
      fetchProjectsTeamMembersList({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type ProjectsUpdateMaturityGridUpdatePathParams = {
  /**
   * Project ID
   */
  id: string;
};

export type ProjectsUpdateMaturityGridUpdateError =
  Fetcher.ErrorWrapper<undefined>;

export type ProjectsUpdateMaturityGridUpdateVariables = {
  pathParams: ProjectsUpdateMaturityGridUpdatePathParams;
} & OneApiContext['fetcherOptions'];

export const fetchProjectsUpdateMaturityGridUpdate = (
  variables: ProjectsUpdateMaturityGridUpdateVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    undefined,
    ProjectsUpdateMaturityGridUpdateError,
    undefined,
    {},
    {},
    ProjectsUpdateMaturityGridUpdatePathParams
  >({
    url: '/projects/{id}/update-maturity-grid',
    method: 'put',
    ...variables,
    signal,
  });

export const useProjectsUpdateMaturityGridUpdate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      ProjectsUpdateMaturityGridUpdateError,
      ProjectsUpdateMaturityGridUpdateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useOneApiContext();
  return reactQuery.useMutation<
    undefined,
    ProjectsUpdateMaturityGridUpdateError,
    ProjectsUpdateMaturityGridUpdateVariables
  >(
    (variables: ProjectsUpdateMaturityGridUpdateVariables) =>
      fetchProjectsUpdateMaturityGridUpdate({
        ...fetcherOptions,
        ...variables,
      }),
    options
  );
};

export type ProjectsContextsListPathParams = {
  projectPk: string;
};

export type ProjectsContextsListError = Fetcher.ErrorWrapper<undefined>;

export type ProjectsContextsListResponse = Schemas.Context[];

export type ProjectsContextsListVariables = {
  pathParams: ProjectsContextsListPathParams;
} & OneApiContext['fetcherOptions'];

export const fetchProjectsContextsList = (
  variables: ProjectsContextsListVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    ProjectsContextsListResponse,
    ProjectsContextsListError,
    undefined,
    {},
    {},
    ProjectsContextsListPathParams
  >({
    url: '/projects/{projectPk}/contexts',
    method: 'get',
    ...variables,
    signal,
  });

export const useProjectsContextsList = <TData = ProjectsContextsListResponse>(
  variables: ProjectsContextsListVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      ProjectsContextsListResponse,
      ProjectsContextsListError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<
    ProjectsContextsListResponse,
    ProjectsContextsListError,
    TData
  >(
    queryKeyFn({
      path: '/projects/{project_pk}/contexts',
      operationId: 'projectsContextsList',
      variables,
    }),
    ({ signal }) =>
      fetchProjectsContextsList({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type ProjectsContextsCreatePathParams = {
  projectPk: string;
};

export type ProjectsContextsCreateError = Fetcher.ErrorWrapper<undefined>;

export type ProjectsContextsCreateVariables = {
  pathParams: ProjectsContextsCreatePathParams;
} & OneApiContext['fetcherOptions'];

export const fetchProjectsContextsCreate = (
  variables: ProjectsContextsCreateVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    undefined,
    ProjectsContextsCreateError,
    undefined,
    {},
    {},
    ProjectsContextsCreatePathParams
  >({
    url: '/projects/{projectPk}/contexts',
    method: 'post',
    ...variables,
    signal,
  });

export const useProjectsContextsCreate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      ProjectsContextsCreateError,
      ProjectsContextsCreateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useOneApiContext();
  return reactQuery.useMutation<
    undefined,
    ProjectsContextsCreateError,
    ProjectsContextsCreateVariables
  >(
    (variables: ProjectsContextsCreateVariables) =>
      fetchProjectsContextsCreate({ ...fetcherOptions, ...variables }),
    options
  );
};

export type ProjectsContextsReadPathParams = {
  projectPk: string;
  contextPk: string;
};

export type ProjectsContextsReadError = Fetcher.ErrorWrapper<undefined>;

export type ProjectsContextsReadVariables = {
  pathParams: ProjectsContextsReadPathParams;
} & OneApiContext['fetcherOptions'];

export const fetchProjectsContextsRead = (
  variables: ProjectsContextsReadVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    Schemas.Context,
    ProjectsContextsReadError,
    undefined,
    {},
    {},
    ProjectsContextsReadPathParams
  >({
    url: '/projects/{projectPk}/contexts/{contextPk}',
    method: 'get',
    ...variables,
    signal,
  });

export const useProjectsContextsRead = <TData = Schemas.Context>(
  variables: ProjectsContextsReadVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.Context,
      ProjectsContextsReadError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<Schemas.Context, ProjectsContextsReadError, TData>(
    queryKeyFn({
      path: '/projects/{project_pk}/contexts/{context_pk}',
      operationId: 'projectsContextsRead',
      variables,
    }),
    ({ signal }) =>
      fetchProjectsContextsRead({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type ProjectsContextsUpdatePathParams = {
  projectPk: string;
  contextPk: string;
};

export type ProjectsContextsUpdateError = Fetcher.ErrorWrapper<undefined>;

export type ProjectsContextsUpdateVariables = {
  pathParams: ProjectsContextsUpdatePathParams;
} & OneApiContext['fetcherOptions'];

export const fetchProjectsContextsUpdate = (
  variables: ProjectsContextsUpdateVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    undefined,
    ProjectsContextsUpdateError,
    undefined,
    {},
    {},
    ProjectsContextsUpdatePathParams
  >({
    url: '/projects/{projectPk}/contexts/{contextPk}',
    method: 'put',
    ...variables,
    signal,
  });

export const useProjectsContextsUpdate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      ProjectsContextsUpdateError,
      ProjectsContextsUpdateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useOneApiContext();
  return reactQuery.useMutation<
    undefined,
    ProjectsContextsUpdateError,
    ProjectsContextsUpdateVariables
  >(
    (variables: ProjectsContextsUpdateVariables) =>
      fetchProjectsContextsUpdate({ ...fetcherOptions, ...variables }),
    options
  );
};

export type ProjectsContextsDeletePathParams = {
  projectPk: string;
  contextPk: string;
};

export type ProjectsContextsDeleteError = Fetcher.ErrorWrapper<undefined>;

export type ProjectsContextsDeleteVariables = {
  pathParams: ProjectsContextsDeletePathParams;
} & OneApiContext['fetcherOptions'];

export const fetchProjectsContextsDelete = (
  variables: ProjectsContextsDeleteVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    undefined,
    ProjectsContextsDeleteError,
    undefined,
    {},
    {},
    ProjectsContextsDeletePathParams
  >({
    url: '/projects/{projectPk}/contexts/{contextPk}',
    method: 'delete',
    ...variables,
    signal,
  });

export const useProjectsContextsDelete = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      ProjectsContextsDeleteError,
      ProjectsContextsDeleteVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useOneApiContext();
  return reactQuery.useMutation<
    undefined,
    ProjectsContextsDeleteError,
    ProjectsContextsDeleteVariables
  >(
    (variables: ProjectsContextsDeleteVariables) =>
      fetchProjectsContextsDelete({ ...fetcherOptions, ...variables }),
    options
  );
};

export type ProjectsContextsRepositoriesListPathParams = {
  projectPk: string;
  contextPk: string;
};

export type ProjectsContextsRepositoriesListError =
  Fetcher.ErrorWrapper<undefined>;

export type ProjectsContextsRepositoriesListResponse = Schemas.Repository[];

export type ProjectsContextsRepositoriesListVariables = {
  pathParams: ProjectsContextsRepositoriesListPathParams;
} & OneApiContext['fetcherOptions'];

export const fetchProjectsContextsRepositoriesList = (
  variables: ProjectsContextsRepositoriesListVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    ProjectsContextsRepositoriesListResponse,
    ProjectsContextsRepositoriesListError,
    undefined,
    {},
    {},
    ProjectsContextsRepositoriesListPathParams
  >({
    url: '/projects/{projectPk}/contexts/{contextPk}/repositories',
    method: 'get',
    ...variables,
    signal,
  });

export const useProjectsContextsRepositoriesList = <
  TData = ProjectsContextsRepositoriesListResponse
>(
  variables: ProjectsContextsRepositoriesListVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      ProjectsContextsRepositoriesListResponse,
      ProjectsContextsRepositoriesListError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<
    ProjectsContextsRepositoriesListResponse,
    ProjectsContextsRepositoriesListError,
    TData
  >(
    queryKeyFn({
      path: '/projects/{project_pk}/contexts/{context_pk}/repositories',
      operationId: 'projectsContextsRepositoriesList',
      variables,
    }),
    ({ signal }) =>
      fetchProjectsContextsRepositoriesList(
        { ...fetcherOptions, ...variables },
        signal
      ),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type ProjectsPersonsListPathParams = {
  projectPk: string;
};

export type ProjectsPersonsListError = Fetcher.ErrorWrapper<undefined>;

export type ProjectsPersonsListResponse = Schemas.Person[];

export type ProjectsPersonsListVariables = {
  pathParams: ProjectsPersonsListPathParams;
} & OneApiContext['fetcherOptions'];

export const fetchProjectsPersonsList = (
  variables: ProjectsPersonsListVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    ProjectsPersonsListResponse,
    ProjectsPersonsListError,
    undefined,
    {},
    {},
    ProjectsPersonsListPathParams
  >({
    url: '/projects/{projectPk}/persons',
    method: 'get',
    ...variables,
    signal,
  });

export const useProjectsPersonsList = <TData = ProjectsPersonsListResponse>(
  variables: ProjectsPersonsListVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      ProjectsPersonsListResponse,
      ProjectsPersonsListError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<
    ProjectsPersonsListResponse,
    ProjectsPersonsListError,
    TData
  >(
    queryKeyFn({
      path: '/projects/{project_pk}/persons',
      operationId: 'projectsPersonsList',
      variables,
    }),
    ({ signal }) =>
      fetchProjectsPersonsList({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type ProjectsPersonsCreatePathParams = {
  projectPk: string;
};

export type ProjectsPersonsCreateError = Fetcher.ErrorWrapper<undefined>;

export type ProjectsPersonsCreateVariables = {
  pathParams: ProjectsPersonsCreatePathParams;
} & OneApiContext['fetcherOptions'];

export const fetchProjectsPersonsCreate = (
  variables: ProjectsPersonsCreateVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    undefined,
    ProjectsPersonsCreateError,
    undefined,
    {},
    {},
    ProjectsPersonsCreatePathParams
  >({
    url: '/projects/{projectPk}/persons',
    method: 'post',
    ...variables,
    signal,
  });

export const useProjectsPersonsCreate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      ProjectsPersonsCreateError,
      ProjectsPersonsCreateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useOneApiContext();
  return reactQuery.useMutation<
    undefined,
    ProjectsPersonsCreateError,
    ProjectsPersonsCreateVariables
  >(
    (variables: ProjectsPersonsCreateVariables) =>
      fetchProjectsPersonsCreate({ ...fetcherOptions, ...variables }),
    options
  );
};

export type ProjectsPersonsReadPathParams = {
  projectPk: string;
  personPk: string;
};

export type ProjectsPersonsReadError = Fetcher.ErrorWrapper<undefined>;

export type ProjectsPersonsReadVariables = {
  pathParams: ProjectsPersonsReadPathParams;
} & OneApiContext['fetcherOptions'];

export const fetchProjectsPersonsRead = (
  variables: ProjectsPersonsReadVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    Schemas.Person,
    ProjectsPersonsReadError,
    undefined,
    {},
    {},
    ProjectsPersonsReadPathParams
  >({
    url: '/projects/{projectPk}/persons/{personPk}',
    method: 'get',
    ...variables,
    signal,
  });

export const useProjectsPersonsRead = <TData = Schemas.Person>(
  variables: ProjectsPersonsReadVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.Person, ProjectsPersonsReadError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<Schemas.Person, ProjectsPersonsReadError, TData>(
    queryKeyFn({
      path: '/projects/{project_pk}/persons/{person_pk}',
      operationId: 'projectsPersonsRead',
      variables,
    }),
    ({ signal }) =>
      fetchProjectsPersonsRead({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type ProjectsPersonsUpdatePathParams = {
  projectPk: string;
  personPk: string;
};

export type ProjectsPersonsUpdateError = Fetcher.ErrorWrapper<undefined>;

export type ProjectsPersonsUpdateVariables = {
  pathParams: ProjectsPersonsUpdatePathParams;
} & OneApiContext['fetcherOptions'];

export const fetchProjectsPersonsUpdate = (
  variables: ProjectsPersonsUpdateVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    undefined,
    ProjectsPersonsUpdateError,
    undefined,
    {},
    {},
    ProjectsPersonsUpdatePathParams
  >({
    url: '/projects/{projectPk}/persons/{personPk}',
    method: 'put',
    ...variables,
    signal,
  });

export const useProjectsPersonsUpdate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      ProjectsPersonsUpdateError,
      ProjectsPersonsUpdateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useOneApiContext();
  return reactQuery.useMutation<
    undefined,
    ProjectsPersonsUpdateError,
    ProjectsPersonsUpdateVariables
  >(
    (variables: ProjectsPersonsUpdateVariables) =>
      fetchProjectsPersonsUpdate({ ...fetcherOptions, ...variables }),
    options
  );
};

export type ProjectsPersonsDeletePathParams = {
  projectPk: string;
  personPk: string;
};

export type ProjectsPersonsDeleteError = Fetcher.ErrorWrapper<undefined>;

export type ProjectsPersonsDeleteVariables = {
  pathParams: ProjectsPersonsDeletePathParams;
} & OneApiContext['fetcherOptions'];

export const fetchProjectsPersonsDelete = (
  variables: ProjectsPersonsDeleteVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    undefined,
    ProjectsPersonsDeleteError,
    undefined,
    {},
    {},
    ProjectsPersonsDeletePathParams
  >({
    url: '/projects/{projectPk}/persons/{personPk}',
    method: 'delete',
    ...variables,
    signal,
  });

export const useProjectsPersonsDelete = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      ProjectsPersonsDeleteError,
      ProjectsPersonsDeleteVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useOneApiContext();
  return reactQuery.useMutation<
    undefined,
    ProjectsPersonsDeleteError,
    ProjectsPersonsDeleteVariables
  >(
    (variables: ProjectsPersonsDeleteVariables) =>
      fetchProjectsPersonsDelete({ ...fetcherOptions, ...variables }),
    options
  );
};

export type ProjectsPersonsRolesListPathParams = {
  projectPk: string;
  personPk: string;
};

export type ProjectsPersonsRolesListError = Fetcher.ErrorWrapper<undefined>;

export type ProjectsPersonsRolesListVariables = {
  pathParams: ProjectsPersonsRolesListPathParams;
} & OneApiContext['fetcherOptions'];

export const fetchProjectsPersonsRolesList = (
  variables: ProjectsPersonsRolesListVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    undefined,
    ProjectsPersonsRolesListError,
    undefined,
    {},
    {},
    ProjectsPersonsRolesListPathParams
  >({
    url: '/projects/{projectPk}/persons/{personPk}/roles',
    method: 'get',
    ...variables,
    signal,
  });

export const useProjectsPersonsRolesList = <TData = undefined>(
  variables: ProjectsPersonsRolesListVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<undefined, ProjectsPersonsRolesListError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<undefined, ProjectsPersonsRolesListError, TData>(
    queryKeyFn({
      path: '/projects/{project_pk}/persons/{person_pk}/roles',
      operationId: 'projectsPersonsRolesList',
      variables,
    }),
    ({ signal }) =>
      fetchProjectsPersonsRolesList(
        { ...fetcherOptions, ...variables },
        signal
      ),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type ProjectsRepositoriesListPathParams = {
  projectPk: string;
};

export type ProjectsRepositoriesListError = Fetcher.ErrorWrapper<undefined>;

export type ProjectsRepositoriesListResponse = Schemas.Repository[];

export type ProjectsRepositoriesListVariables = {
  pathParams: ProjectsRepositoriesListPathParams;
} & OneApiContext['fetcherOptions'];

export const fetchProjectsRepositoriesList = (
  variables: ProjectsRepositoriesListVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    ProjectsRepositoriesListResponse,
    ProjectsRepositoriesListError,
    undefined,
    {},
    {},
    ProjectsRepositoriesListPathParams
  >({
    url: '/projects/{projectPk}/repositories',
    method: 'get',
    ...variables,
    signal,
  });

export const useProjectsRepositoriesList = <
  TData = ProjectsRepositoriesListResponse
>(
  variables: ProjectsRepositoriesListVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      ProjectsRepositoriesListResponse,
      ProjectsRepositoriesListError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<
    ProjectsRepositoriesListResponse,
    ProjectsRepositoriesListError,
    TData
  >(
    queryKeyFn({
      path: '/projects/{project_pk}/repositories',
      operationId: 'projectsRepositoriesList',
      variables,
    }),
    ({ signal }) =>
      fetchProjectsRepositoriesList(
        { ...fetcherOptions, ...variables },
        signal
      ),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type ProjectsRepositoriesReadPathParams = {
  projectPk: string;
  repositoryPk: string;
};

export type ProjectsRepositoriesReadError = Fetcher.ErrorWrapper<undefined>;

export type ProjectsRepositoriesReadVariables = {
  pathParams: ProjectsRepositoriesReadPathParams;
} & OneApiContext['fetcherOptions'];

export const fetchProjectsRepositoriesRead = (
  variables: ProjectsRepositoriesReadVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    Schemas.Repository,
    ProjectsRepositoriesReadError,
    undefined,
    {},
    {},
    ProjectsRepositoriesReadPathParams
  >({
    url: '/projects/{projectPk}/repositories/{repositoryPk}',
    method: 'get',
    ...variables,
    signal,
  });

export const useProjectsRepositoriesRead = <TData = Schemas.Repository>(
  variables: ProjectsRepositoriesReadVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.Repository,
      ProjectsRepositoriesReadError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<
    Schemas.Repository,
    ProjectsRepositoriesReadError,
    TData
  >(
    queryKeyFn({
      path: '/projects/{project_pk}/repositories/{repository_pk}',
      operationId: 'projectsRepositoriesRead',
      variables,
    }),
    ({ signal }) =>
      fetchProjectsRepositoriesRead(
        { ...fetcherOptions, ...variables },
        signal
      ),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type ProjectsRepositoriesUpdatePathParams = {
  projectPk: string;
  repositoryPk: string;
};

export type ProjectsRepositoriesUpdateError = Fetcher.ErrorWrapper<undefined>;

export type ProjectsRepositoriesUpdateVariables = {
  pathParams: ProjectsRepositoriesUpdatePathParams;
} & OneApiContext['fetcherOptions'];

export const fetchProjectsRepositoriesUpdate = (
  variables: ProjectsRepositoriesUpdateVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    undefined,
    ProjectsRepositoriesUpdateError,
    undefined,
    {},
    {},
    ProjectsRepositoriesUpdatePathParams
  >({
    url: '/projects/{projectPk}/repositories/{repositoryPk}',
    method: 'put',
    ...variables,
    signal,
  });

export const useProjectsRepositoriesUpdate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      ProjectsRepositoriesUpdateError,
      ProjectsRepositoriesUpdateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useOneApiContext();
  return reactQuery.useMutation<
    undefined,
    ProjectsRepositoriesUpdateError,
    ProjectsRepositoriesUpdateVariables
  >(
    (variables: ProjectsRepositoriesUpdateVariables) =>
      fetchProjectsRepositoriesUpdate({ ...fetcherOptions, ...variables }),
    options
  );
};

export type ProjectsTeamsListPathParams = {
  projectPk: string;
};

export type ProjectsTeamsListError = Fetcher.ErrorWrapper<undefined>;

export type ProjectsTeamsListResponse = Schemas.Team[];

export type ProjectsTeamsListVariables = {
  pathParams: ProjectsTeamsListPathParams;
} & OneApiContext['fetcherOptions'];

export const fetchProjectsTeamsList = (
  variables: ProjectsTeamsListVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    ProjectsTeamsListResponse,
    ProjectsTeamsListError,
    undefined,
    {},
    {},
    ProjectsTeamsListPathParams
  >({
    url: '/projects/{projectPk}/teams',
    method: 'get',
    ...variables,
    signal,
  });

export const useProjectsTeamsList = <TData = ProjectsTeamsListResponse>(
  variables: ProjectsTeamsListVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      ProjectsTeamsListResponse,
      ProjectsTeamsListError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<
    ProjectsTeamsListResponse,
    ProjectsTeamsListError,
    TData
  >(
    queryKeyFn({
      path: '/projects/{project_pk}/teams',
      operationId: 'projectsTeamsList',
      variables,
    }),
    ({ signal }) =>
      fetchProjectsTeamsList({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type ProjectsTeamsCreatePathParams = {
  projectPk: string;
};

export type ProjectsTeamsCreateError = Fetcher.ErrorWrapper<undefined>;

export type ProjectsTeamsCreateVariables = {
  pathParams: ProjectsTeamsCreatePathParams;
} & OneApiContext['fetcherOptions'];

export const fetchProjectsTeamsCreate = (
  variables: ProjectsTeamsCreateVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    undefined,
    ProjectsTeamsCreateError,
    undefined,
    {},
    {},
    ProjectsTeamsCreatePathParams
  >({
    url: '/projects/{projectPk}/teams',
    method: 'post',
    ...variables,
    signal,
  });

export const useProjectsTeamsCreate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      ProjectsTeamsCreateError,
      ProjectsTeamsCreateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useOneApiContext();
  return reactQuery.useMutation<
    undefined,
    ProjectsTeamsCreateError,
    ProjectsTeamsCreateVariables
  >(
    (variables: ProjectsTeamsCreateVariables) =>
      fetchProjectsTeamsCreate({ ...fetcherOptions, ...variables }),
    options
  );
};

export type ProjectsTeamsReadPathParams = {
  projectPk: string;
  teamPk: string;
};

export type ProjectsTeamsReadError = Fetcher.ErrorWrapper<undefined>;

export type ProjectsTeamsReadVariables = {
  pathParams: ProjectsTeamsReadPathParams;
} & OneApiContext['fetcherOptions'];

export const fetchProjectsTeamsRead = (
  variables: ProjectsTeamsReadVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    Schemas.Team,
    ProjectsTeamsReadError,
    undefined,
    {},
    {},
    ProjectsTeamsReadPathParams
  >({
    url: '/projects/{projectPk}/teams/{teamPk}',
    method: 'get',
    ...variables,
    signal,
  });

export const useProjectsTeamsRead = <TData = Schemas.Team>(
  variables: ProjectsTeamsReadVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.Team, ProjectsTeamsReadError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<Schemas.Team, ProjectsTeamsReadError, TData>(
    queryKeyFn({
      path: '/projects/{project_pk}/teams/{team_pk}',
      operationId: 'projectsTeamsRead',
      variables,
    }),
    ({ signal }) =>
      fetchProjectsTeamsRead({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type ProjectsTeamsUpdatePathParams = {
  projectPk: string;
  teamPk: string;
};

export type ProjectsTeamsUpdateError = Fetcher.ErrorWrapper<undefined>;

export type ProjectsTeamsUpdateVariables = {
  pathParams: ProjectsTeamsUpdatePathParams;
} & OneApiContext['fetcherOptions'];

export const fetchProjectsTeamsUpdate = (
  variables: ProjectsTeamsUpdateVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    undefined,
    ProjectsTeamsUpdateError,
    undefined,
    {},
    {},
    ProjectsTeamsUpdatePathParams
  >({
    url: '/projects/{projectPk}/teams/{teamPk}',
    method: 'put',
    ...variables,
    signal,
  });

export const useProjectsTeamsUpdate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      ProjectsTeamsUpdateError,
      ProjectsTeamsUpdateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useOneApiContext();
  return reactQuery.useMutation<
    undefined,
    ProjectsTeamsUpdateError,
    ProjectsTeamsUpdateVariables
  >(
    (variables: ProjectsTeamsUpdateVariables) =>
      fetchProjectsTeamsUpdate({ ...fetcherOptions, ...variables }),
    options
  );
};

export type ProjectsTeamsDeletePathParams = {
  projectPk: string;
  teamPk: string;
};

export type ProjectsTeamsDeleteError = Fetcher.ErrorWrapper<undefined>;

export type ProjectsTeamsDeleteVariables = {
  pathParams: ProjectsTeamsDeletePathParams;
} & OneApiContext['fetcherOptions'];

export const fetchProjectsTeamsDelete = (
  variables: ProjectsTeamsDeleteVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    undefined,
    ProjectsTeamsDeleteError,
    undefined,
    {},
    {},
    ProjectsTeamsDeletePathParams
  >({
    url: '/projects/{projectPk}/teams/{teamPk}',
    method: 'delete',
    ...variables,
    signal,
  });

export const useProjectsTeamsDelete = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      ProjectsTeamsDeleteError,
      ProjectsTeamsDeleteVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useOneApiContext();
  return reactQuery.useMutation<
    undefined,
    ProjectsTeamsDeleteError,
    ProjectsTeamsDeleteVariables
  >(
    (variables: ProjectsTeamsDeleteVariables) =>
      fetchProjectsTeamsDelete({ ...fetcherOptions, ...variables }),
    options
  );
};

export type ProjectsTeamsPersonsListPathParams = {
  projectPk: string;
  teamPk: string;
};

export type ProjectsTeamsPersonsListError = Fetcher.ErrorWrapper<undefined>;

export type ProjectsTeamsPersonsListResponse = Schemas.Person[];

export type ProjectsTeamsPersonsListVariables = {
  pathParams: ProjectsTeamsPersonsListPathParams;
} & OneApiContext['fetcherOptions'];

export const fetchProjectsTeamsPersonsList = (
  variables: ProjectsTeamsPersonsListVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    ProjectsTeamsPersonsListResponse,
    ProjectsTeamsPersonsListError,
    undefined,
    {},
    {},
    ProjectsTeamsPersonsListPathParams
  >({
    url: '/projects/{projectPk}/teams/{teamPk}/persons',
    method: 'get',
    ...variables,
    signal,
  });

export const useProjectsTeamsPersonsList = <
  TData = ProjectsTeamsPersonsListResponse
>(
  variables: ProjectsTeamsPersonsListVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      ProjectsTeamsPersonsListResponse,
      ProjectsTeamsPersonsListError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<
    ProjectsTeamsPersonsListResponse,
    ProjectsTeamsPersonsListError,
    TData
  >(
    queryKeyFn({
      path: '/projects/{project_pk}/teams/{team_pk}/persons',
      operationId: 'projectsTeamsPersonsList',
      variables,
    }),
    ({ signal }) =>
      fetchProjectsTeamsPersonsList(
        { ...fetcherOptions, ...variables },
        signal
      ),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type UserDetailsListError = Fetcher.ErrorWrapper<undefined>;

export type UserDetailsListVariables = OneApiContext['fetcherOptions'];

export const fetchUserDetailsList = (
  variables: UserDetailsListVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<undefined, UserDetailsListError, undefined, {}, {}, {}>({
    url: '/user-details',
    method: 'get',
    ...variables,
    signal,
  });

export const useUserDetailsList = <TData = undefined>(
  variables: UserDetailsListVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<undefined, UserDetailsListError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<undefined, UserDetailsListError, TData>(
    queryKeyFn({
      path: '/user-details',
      operationId: 'userDetailsList',
      variables,
    }),
    ({ signal }) =>
      fetchUserDetailsList({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type UsersListError = Fetcher.ErrorWrapper<undefined>;

export type UsersListResponse = Schemas.User[];

export type UsersListVariables = OneApiContext['fetcherOptions'];

/**
 * List all users
 */
export const fetchUsersList = (
  variables: UsersListVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<UsersListResponse, UsersListError, undefined, {}, {}, {}>({
    url: '/users',
    method: 'get',
    ...variables,
    signal,
  });

/**
 * List all users
 */
export const useUsersList = <TData = UsersListResponse>(
  variables: UsersListVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<UsersListResponse, UsersListError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<UsersListResponse, UsersListError, TData>(
    queryKeyFn({ path: '/users', operationId: 'usersList', variables }),
    ({ signal }) => fetchUsersList({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type UsersReadPathParams = {
  /**
   * A unique integer value identifying this user.
   */
  id: number;
};

export type UsersReadError = Fetcher.ErrorWrapper<undefined>;

export type UsersReadVariables = {
  pathParams: UsersReadPathParams;
} & OneApiContext['fetcherOptions'];

export const fetchUsersRead = (
  variables: UsersReadVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    Schemas.User,
    UsersReadError,
    undefined,
    {},
    {},
    UsersReadPathParams
  >({ url: '/users/{id}', method: 'get', ...variables, signal });

export const useUsersRead = <TData = Schemas.User>(
  variables: UsersReadVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.User, UsersReadError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<Schemas.User, UsersReadError, TData>(
    queryKeyFn({ path: '/users/{id}', operationId: 'usersRead', variables }),
    ({ signal }) => fetchUsersRead({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type QueryOperation =
  | {
      path: '/assessment-question/{id}';
      operationId: 'assessmentQuestionRead';
      variables: AssessmentQuestionReadVariables;
    }
  | {
      path: '/comments';
      operationId: 'commentsList';
      variables: CommentsListVariables;
    }
  | {
      path: '/comments/{id}';
      operationId: 'commentsRead';
      variables: CommentsReadVariables;
    }
  | {
      path: '/finding-area/{id}/finding-topics';
      operationId: 'findingAreaFindingTopicsList';
      variables: FindingAreaFindingTopicsListVariables;
    }
  | {
      path: '/finding-area/{id}/rating';
      operationId: 'findingAreaRatingList';
      variables: FindingAreaRatingListVariables;
    }
  | {
      path: '/finding-areas/{id}';
      operationId: 'findingAreasRead';
      variables: FindingAreasReadVariables;
    }
  | {
      path: '/finding-groups/{id}';
      operationId: 'findingGroupsRead';
      variables: FindingGroupsReadVariables;
    }
  | {
      path: '/finding-groups/{id}/finding-areas';
      operationId: 'findingGroupsFindingAreasList';
      variables: FindingGroupsFindingAreasListVariables;
    }
  | {
      path: '/finding-groups/{id}/rating';
      operationId: 'findingGroupsRatingList';
      variables: FindingGroupsRatingListVariables;
    }
  | {
      path: '/finding-groups/{id}/tas_rating';
      operationId: 'findingGroupsTasRatingList';
      variables: FindingGroupsTasRatingListVariables;
    }
  | {
      path: '/finding-reference/{id}';
      operationId: 'findingReferenceRead';
      variables: FindingReferenceReadVariables;
    }
  | {
      path: '/finding-topic-reference/{id}';
      operationId: 'findingTopicReferenceRead';
      variables: FindingTopicReferenceReadVariables;
    }
  | {
      path: '/finding-topic/{id}';
      operationId: 'findingTopicRead';
      variables: FindingTopicReadVariables;
    }
  | {
      path: '/finding-topic/{id}/finding-topic-references';
      operationId: 'findingTopicFindingTopicReferencesList';
      variables: FindingTopicFindingTopicReferencesListVariables;
    }
  | {
      path: '/finding-topic/{id}/maturity-ratings';
      operationId: 'findingTopicMaturityRatingsList';
      variables: FindingTopicMaturityRatingsListVariables;
    }
  | {
      path: '/finding-topic/{topic_pk}/assessment-questions';
      operationId: 'findingTopicAssessmentQuestionsList';
      variables: FindingTopicAssessmentQuestionsListVariables;
    }
  | {
      path: '/findings/{id}';
      operationId: 'findingsRead';
      variables: FindingsReadVariables;
    }
  | {
      path: '/findings/{id}/finding-references';
      operationId: 'findingsFindingReferencesList';
      variables: FindingsFindingReferencesListVariables;
    }
  | {
      path: '/maturity-rating/{id}';
      operationId: 'maturityRatingRead';
      variables: MaturityRatingReadVariables;
    }
  | {
      path: '/maturity-view/{ident}/finding-groups-tas-benchmark';
      operationId: 'maturityViewFindingGroupsTasBenchmarkList';
      variables: MaturityViewFindingGroupsTasBenchmarkListVariables;
    }
  | {
      path: '/maturity-view/{id}/finding-groups';
      operationId: 'maturityViewFindingGroupsList';
      variables: MaturityViewFindingGroupsListVariables;
    }
  | {
      path: '/maturity-view/{id}/maturity-bulk';
      operationId: 'maturityViewMaturityBulkRead';
      variables: MaturityViewMaturityBulkReadVariables;
    }
  | {
      path: '/options/{type}';
      operationId: 'optionsRead';
      variables: OptionsReadVariables;
    }
  | {
      path: '/project-collaborators';
      operationId: 'projectCollaboratorsList';
      variables: ProjectCollaboratorsListVariables;
    }
  | {
      path: '/project-team-members/{id}';
      operationId: 'projectTeamMembersRead';
      variables: ProjectTeamMembersReadVariables;
    }
  | {
      path: '/projects';
      operationId: 'projectsList';
      variables: ProjectsListVariables;
    }
  | {
      path: '/projects/excel-export/{project_pk}';
      operationId: 'projectsExcelExportRead';
      variables: ProjectsExcelExportReadVariables;
    }
  | {
      path: '/projects/metadata-options/client-industries';
      operationId: 'projectsMetadataOptionsClientIndustriesList';
      variables: ProjectsMetadataOptionsClientIndustriesListVariables;
    }
  | {
      path: '/projects/metadata-options/industries';
      operationId: 'projectsMetadataOptionsIndustriesList';
      variables: ProjectsMetadataOptionsIndustriesListVariables;
    }
  | {
      path: '/projects/metadata-options/project-types';
      operationId: 'projectsMetadataOptionsProjectTypesList';
      variables: ProjectsMetadataOptionsProjectTypesListVariables;
    }
  | {
      path: '/projects/metadata-options/sizes';
      operationId: 'projectsMetadataOptionsSizesList';
      variables: ProjectsMetadataOptionsSizesListVariables;
    }
  | {
      path: '/projects/metadata-options/stages';
      operationId: 'projectsMetadataOptionsStagesList';
      variables: ProjectsMetadataOptionsStagesListVariables;
    }
  | {
      path: '/projects/{id}';
      operationId: 'projectsRead';
      variables: ProjectsReadVariables;
    }
  | {
      path: '/projects/{id}/dashboard-url';
      operationId: 'projectsDashboardUrlList';
      variables: ProjectsDashboardUrlListVariables;
    }
  | {
      path: '/projects/{id}/export-maturity';
      operationId: 'projectsExportMaturityList';
      variables: ProjectsExportMaturityListVariables;
    }
  | {
      path: '/projects/{id}/files/{path_b64}';
      operationId: 'projectsFilesRead';
      variables: ProjectsFilesReadVariables;
    }
  | {
      path: '/projects/{id}/finding-areas';
      operationId: 'projectsFindingAreasList';
      variables: ProjectsFindingAreasListVariables;
    }
  | {
      path: '/projects/{id}/finding-groups';
      operationId: 'projectsFindingGroupsList';
      variables: ProjectsFindingGroupsListVariables;
    }
  | {
      path: '/projects/{id}/finding-groups/rating';
      operationId: 'projectsFindingGroupsRatingList';
      variables: ProjectsFindingGroupsRatingListVariables;
    }
  | {
      path: '/projects/{id}/findings';
      operationId: 'projectsFindingsList';
      variables: ProjectsFindingsListVariables;
    }
  | {
      path: '/projects/{id}/findings/strengths';
      operationId: 'projectsFindingsStrengthsList';
      variables: ProjectsFindingsStrengthsListVariables;
    }
  | {
      path: '/projects/{id}/findings/weaknesses';
      operationId: 'projectsFindingsWeaknessesList';
      variables: ProjectsFindingsWeaknessesListVariables;
    }
  | {
      path: '/projects/{id}/maturity-bulk';
      operationId: 'projectsMaturityBulkRead';
      variables: ProjectsMaturityBulkReadVariables;
    }
  | {
      path: '/projects/{id}/maturity-views';
      operationId: 'projectsMaturityViewsList';
      variables: ProjectsMaturityViewsListVariables;
    }
  | {
      path: '/projects/{id}/merged-sanity-check';
      operationId: 'projectsMergedSanityCheckList';
      variables: ProjectsMergedSanityCheckListVariables;
    }
  | {
      path: '/projects/{id}/relevant-questions';
      operationId: 'projectsRelevantQuestionsList';
      variables: ProjectsRelevantQuestionsListVariables;
    }
  | {
      path: '/projects/{id}/reporting-charts';
      operationId: 'projectsReportingChartsList';
      variables: ProjectsReportingChartsListVariables;
    }
  | {
      path: '/projects/{id}/service-modules';
      operationId: 'projectsServiceModulesList';
      variables: ProjectsServiceModulesListVariables;
    }
  | {
      path: '/projects/{id}/team-members';
      operationId: 'projectsTeamMembersList';
      variables: ProjectsTeamMembersListVariables;
    }
  | {
      path: '/projects/{project_pk}/contexts';
      operationId: 'projectsContextsList';
      variables: ProjectsContextsListVariables;
    }
  | {
      path: '/projects/{project_pk}/contexts/{context_pk}';
      operationId: 'projectsContextsRead';
      variables: ProjectsContextsReadVariables;
    }
  | {
      path: '/projects/{project_pk}/contexts/{context_pk}/repositories';
      operationId: 'projectsContextsRepositoriesList';
      variables: ProjectsContextsRepositoriesListVariables;
    }
  | {
      path: '/projects/{project_pk}/persons';
      operationId: 'projectsPersonsList';
      variables: ProjectsPersonsListVariables;
    }
  | {
      path: '/projects/{project_pk}/persons/{person_pk}';
      operationId: 'projectsPersonsRead';
      variables: ProjectsPersonsReadVariables;
    }
  | {
      path: '/projects/{project_pk}/persons/{person_pk}/roles';
      operationId: 'projectsPersonsRolesList';
      variables: ProjectsPersonsRolesListVariables;
    }
  | {
      path: '/projects/{project_pk}/repositories';
      operationId: 'projectsRepositoriesList';
      variables: ProjectsRepositoriesListVariables;
    }
  | {
      path: '/projects/{project_pk}/repositories/{repository_pk}';
      operationId: 'projectsRepositoriesRead';
      variables: ProjectsRepositoriesReadVariables;
    }
  | {
      path: '/projects/{project_pk}/teams';
      operationId: 'projectsTeamsList';
      variables: ProjectsTeamsListVariables;
    }
  | {
      path: '/projects/{project_pk}/teams/{team_pk}';
      operationId: 'projectsTeamsRead';
      variables: ProjectsTeamsReadVariables;
    }
  | {
      path: '/projects/{project_pk}/teams/{team_pk}/persons';
      operationId: 'projectsTeamsPersonsList';
      variables: ProjectsTeamsPersonsListVariables;
    }
  | {
      path: '/user-details';
      operationId: 'userDetailsList';
      variables: UserDetailsListVariables;
    }
  | {
      path: '/users';
      operationId: 'usersList';
      variables: UsersListVariables;
    }
  | {
      path: '/users/{id}';
      operationId: 'usersRead';
      variables: UsersReadVariables;
    };
