import React from 'react';
import {
  onePermGroups as onePermsGroups,
  onePerms,
  projectRoleGroups,
  projectRoles,
} from '../utils/oneAuthorization';
import { projectTypes } from '../model/project_model';
import Icon from '../components/Icon';
import { Redirect } from 'react-router-dom';
import Dashboard from '../pages/dashboard';

const ListQuestionnaires = React.lazy(
  () => import('../pages/questionnaire/questionnairesList')
);
const AllQuestions = React.lazy(
  () => import('../pages/questionnaire/allQuestions')
);
const ResponsesView = React.lazy(
  () => import('../pages/questionnaire/responsesView')
);
const ProjectsAdmin = React.lazy(
  () => import('../pages/projects/ProjectsAdmin')
);
const DataExplorer = React.lazy(
  () => import('../pages/data-explorer/DataExplorer')
);
const ListUsers = React.lazy(() => import('../pages/users/ListUsers'));
const FindingAreaList = React.lazy(
  () => import('../pages/findingAreas/admin/FindingAreaList')
);
const ProjectSummary = React.lazy(() => import('../pages/reportSummary'));
const Page404 = React.lazy(() => import('../pages/public/Page404'));
const Page500 = React.lazy(() => import('../pages/public/Page500'));
const ProjectOverview = React.lazy(
  () => import('../pages/projects/overview/ProjectOverview')
);
const IndicatorList = React.lazy(
  () => import('../pages/indicators/IndicatorList')
);
const Notes = React.lazy(() => import('../pages/notes'));
const Welcome = React.lazy(() => import('../pages/public/Welcome'));
const MaturityModel = React.lazy(() => import('../pages/maturityModel'));
const Findings = React.lazy(() => import('../pages/findings'));
const TeamOverview = React.lazy(() => import('../pages/team/teamOverview'));
const TeamDetails = React.lazy(() => import('../pages/team/teamDetails'));
const PersonDetails = React.lazy(() => import('../pages/team/personDetails'));
const RepositoryOverview = React.lazy(
  () => import('../pages/repositories/RepositoryOverview')
);
const RepositoryDetails = React.lazy(
  () => import('../pages/repositories/RepositoryDetails')
);
const ProjectSetup = React.lazy(() => import('../pages/projectSetup'));
const Conclusion = React.lazy(() => import('../pages/conclusion'));

const dashboard = {
  id: 'Dashboard',
  path: ['/'],
  component: Dashboard,
  validPerms: [onePerms.COLLABORATE_ON_PROJECTS],
};

const projectSelection = {
  id: 'Projects',
  path: ['/projects'],
  component: ProjectOverview,
  validPerms: [onePerms.COLLABORATE_ON_PROJECTS],
};

const projectMainPage = {
  id: 'Project',
  path: ['/projects/:projectIdent'],
  component: () => <Redirect to={'/reporting'} />,
  validRoles: projectRoleGroups.REPORT_VIEWERS,
};

const reportingRoutes = {
  header: 'Reporting',
  id: 'Report Summary',
  path: ['/projects/:projectIdent/reporting', '/reporting'],
  icon: <Icon category="navigation" name="nav-report_summary" />,
  component: ProjectSummary,
  validRoles: projectRoleGroups.REPORT_VIEWERS,
};

const findingRoutes = {
  id: 'Findings',
  path: [
    '/projects/:projectIdent/findings/:findingId?',
    '/findings/:findingId?',
  ],
  navPath: '/projects/:projectIdent/findings',
  icon: <Icon category="navigation" name="nav-findings" />,
  component: Findings,
  validRoles: projectRoleGroups.REPORT_VIEWERS,
  validProjectTypes: [projectTypes.DUE_DILIGENCE],
};

const maturityHome = {
  id: 'Maturity Model',
  path: ['/projects/:projectIdent/maturity-model', '/maturity-model'],
  icon: <Icon category="navigation" name="nav-maturity_rating" />,
  component: MaturityModel,
  validRoles: projectRoleGroups.REPORT_VIEWERS,
  validProjectTypes: [projectTypes.DUE_DILIGENCE],
};

const dataRoutes = {
  id: 'Data Explorer',
  path: ['/projects/:projectIdent/data-explorer', '/data-explorer'],
  component: DataExplorer,
  icon: <Icon category="navigation" name="nav-data_explorer" />,
  validRoles: [projectRoles.PROJECT_ADMIN, projectRoles.PROJECT_ANALYST],
};
const questionnaireDetailsRoutes = {
  path: [
    '/projects/:projectIdent/questionnaires/answer/:id',
    '/questionnaires/answer/:id',
  ],
  name: 'Questionnaire Answer',
  component: ResponsesView,
  validPerms: [onePerms.POLL_AUDIT_ANSWERS],
  validProjectTypes: [projectTypes.DUE_DILIGENCE],
};
const questionnairesAllQuestions = {
  path: [
    '/projects/:projectIdent/questionnaires/questions',
    '/questionnaires/questions',
  ],
  name: "Questionnaire's Questions",
  component: AllQuestions,
  validRoles: [projectRoles.PROJECT_ADMIN, projectRoles.PROJECT_ANALYST],
};

const assessmentSourcesArea = [
  {
    header: 'Assessment Sources',
    id: 'Teams',
    path: ['/projects/:projectIdent/teams', '/teams'],
    icon: <Icon category="navigation" name="nav-teams" />,
    component: TeamOverview,
    validPerms: [onePerms.ONE_EXPERIMENTAL_FEATURES],
    validRoles: projectRoleGroups.REPORT_VIEWERS,
    validProjectTypes: [projectTypes.DUE_DILIGENCE],
  },
  {
    id: 'Repositories',
    path: ['/projects/:projectIdent/repositories', '/repositories'],
    icon: <Icon category="navigation" name="nav-repositories" />,
    component: RepositoryOverview,
    validPerms: [onePerms.ONE_EXPERIMENTAL_FEATURES],
    validRoles: projectRoleGroups.REPORT_VIEWERS,
    validProjectTypes: [projectTypes.DUE_DILIGENCE],
  },
  {
    id: 'Indicators',
    path: [
      '/projects/:projectIdent/reporting/sources/indicators',
      '/reporting/sources/indicators',
    ],
    icon: <Icon category="navigation" name="nav-indicators" />,
    component: IndicatorList,
    validPerms: [onePerms.ONE_EXPERIMENTAL_FEATURES],
    validRoles: projectRoleGroups.REPORT_VIEWERS,
    validProjectTypes: [projectTypes.DUE_DILIGENCE],
  },
  {
    id: 'Questionnaires',
    path: ['/projects/:projectIdent/questionnaires', '/questionnaires'],
    icon: <Icon category="navigation" name="nav-questionnaire" />,
    component: ListQuestionnaires,
    validRoles: [projectRoles.PROJECT_ADMIN, projectRoles.PROJECT_ANALYST],
    validProjectTypes: [projectTypes.DUE_DILIGENCE],
  },
  {
    id: 'Notes',
    path: ['/projects/:projectIdent/notes', '/notes'],
    icon: <Icon category="navigation" name="nav-interview_notes" />,
    component: Notes,
    validPerms: [onePerms.ONE_EXPERIMENTAL_FEATURES],
    validRoles: projectRoleGroups.REPORT_VIEWERS,
    validProjectTypes: [projectTypes.DUE_DILIGENCE],
  },
];
const resourceDetailRoutes = [
  {
    path: ['/projects/:projectIdent/teams/:id', '/teams/:id'],
    name: 'Team Details',
    component: TeamDetails,
    validRoles: projectRoleGroups.REPORT_VIEWERS,
    validProjectTypes: [projectTypes.DUE_DILIGENCE],
  },
  {
    path: ['/projects/:projectIdent/persons/:id', '/persons/:id'],
    name: 'Person Details',
    component: PersonDetails,
    validRoles: projectRoleGroups.REPORT_VIEWERS,
    validProjectTypes: [projectTypes.DUE_DILIGENCE],
  },
  {
    path: ['/projects/:projectIdent/repositories/:id', '/repositories/:id'],
    name: 'Repository Details',
    component: RepositoryDetails,
    validRoles: projectRoleGroups.REPORT_VIEWERS,
    validProjectTypes: [projectTypes.DUE_DILIGENCE],
  },
];

const projectSetupRoutes = [
  {
    path: ['/projects/:projectIdent/project-setup/team', '/project-setup/team'],
    component: () => <ProjectSetup defaultTab="TEAM" />,
    validRoles: [projectRoles.PROJECT_ADMIN],
  },
  {
    path: [
      '/projects/:projectIdent/project-setup/client-and-target',
      '/project-setup/client-and-target',
    ],
    component: () => <ProjectSetup defaultTab="CLIENT_AND_TARGET" />,
    validRoles: [projectRoles.PROJECT_ADMIN],
  },
  {
    path: [
      '/projects/:projectIdent/project-setup/maturity-grid',
      '/project-setup/maturity-grid',
    ],
    component: () => <ProjectSetup defaultTab="MATURITY_GRID" />,
    validRoles: [projectRoles.PROJECT_ADMIN],
  },
];
const projectAdminArea = [
  {
    header: 'Project Admin',
    id: 'Project Setup',
    path: [
      '/projects/:projectIdent/project-setup',
      '/projects/:projectIdent/project-setup/global-settings',
      '/project-setup',
      '/project-setup/global-settings',
      ...projectSetupRoutes.map((item) => item.path).flat(),
    ],
    icon: <Icon category="navigation" name="nav-project_setup" />,
    component: () => <ProjectSetup defaultTab="GLOBAL_SETTINGS" />,
    validRoles: [projectRoles.PROJECT_ADMIN],
  },
  {
    id: 'Conclusion',
    path: ['/projects/:projectIdent/conclusion', '/conclusion'],
    icon: <Icon category="navigation" name="nav-archive" />,
    component: () => <Conclusion />,
    validRoles: [projectRoles.PROJECT_ADMIN],
  },
];

const adminRoutes = {
  header: 'ONE Admin',
  id: 'ONE Admin',
  path: '/admin',
  icon: <Icon category="navigation" name="nav-project_setup" />,
  children: [
    {
      path: ['/admin/projects'],
      name: 'Projects',
      component: ProjectsAdmin,
      validPerms: [...onePermsGroups.CREATE_PROJECTS],
    },
    {
      path: ['/admin/users'],
      name: 'Users',
      component: ListUsers,
      validPerms: [onePerms.VIEW_USERS],
    },
    {
      path: ['/admin/finding-areas'],
      name: 'Finding Areas',
      component: FindingAreaList,
      validPerms: [onePerms.ADMIN_REFERENCE_DATA],
    },
  ],
};

const publicRoutes = {
  id: 'Auth',
  path: '/public',
  children: [
    {
      path: '/public/404',
      name: '404 Page',
      component: Page404,
    },
    {
      path: '/public/500',
      name: '500 Page',
      component: Page500,
    },
    {
      path: '/public/welcome',
      name: 'Welcome',
      component: Welcome,
    },
  ],
};

const navigationRoutes = [
  reportingRoutes,
  findingRoutes,
  maturityHome,
  ...assessmentSourcesArea,
  dataRoutes,
  ...projectAdminArea,
  adminRoutes,
];

export const protectedRoutes = [
  reportingRoutes,
  findingRoutes,
  maturityHome,
  ...assessmentSourcesArea,
  ...resourceDetailRoutes,
  dataRoutes,
  ...projectSetupRoutes,
  ...projectAdminArea,
  questionnaireDetailsRoutes,
  questionnairesAllQuestions,
  adminRoutes,
  projectMainPage,
  projectSelection,
  dashboard,
];

export const unprotectedRoutes = [publicRoutes];

export default navigationRoutes;
