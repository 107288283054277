import React from 'react';
import Helmet from 'react-helmet';
import useHotjar from './hooks/useHotjar';
import Routes from './routes/Routes';

const App = () => {
  useHotjar();
  return (
    <>
      <Helmet
        titleTemplate="%s | TechMiners ONE"
        defaultTitle="TechMiners ONE"
      />
      <Routes />
    </>
  );
};

export default App;
