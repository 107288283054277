import type { QueryKey, UseQueryOptions } from '@tanstack/react-query';
import { ResponseType } from 'axios';
import { useAuthToken } from '../../context/AuthTokenContext';
import { QueryOperation } from './oneApiComponents';

export type OneApiContext = {
  fetcherOptions: {
    /**
     * Headers to inject in the fetcher
     */
    headers?: Record<string, unknown>;
    /**
     * Query params to inject in the fetcher
     */
    queryParams?: Record<string, unknown>;
    /**
     * to set custom response type
     */
    responseType?: ResponseType;
    /**
     * to set custom queryKey
     */
    queryKey?: string[];
  };
  queryOptions: {
    /**
     * Set this to `false` to disable automatic refetching when the query mounts or changes query keys.
     * Defaults to `true`.
     */
    enabled?: boolean;
    /**
     * for default global error handling.
     */
    onError?: (err: any) => void;
  };
  /**
   * Query key manager.
   */
  queryKeyFn: (operation: QueryOperation) => QueryKey;
};

/**
 * Context injected into every react-query hook wrappers
 *
 * @param queryOptions options from the useQuery wrapper
 */
export function useOneApiContext<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
>(
  queryOptions?: UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>
): OneApiContext {
  const { token, loading } = useAuthToken();

  return {
    fetcherOptions: {},
    queryOptions: {
      enabled: !!token && !loading && (queryOptions?.enabled ?? true),
    },
    queryKeyFn: (operation) => {
      // if custom query key has been provided
      if (queryOptions?.queryKey) return queryOptions.queryKey;

      const queryKey: unknown[] = hasPathParams(operation)
        ? operation.path
            .split('/')
            .filter(Boolean)
            .map((i) => resolvePathParam(i, operation.variables.pathParams))
        : operation.path.split('/').filter(Boolean);

      if (hasQueryParams(operation)) {
        queryKey.push(operation.variables.queryParams);
      }

      if (hasBody(operation)) {
        queryKey.push(operation.variables.body);
      }

      return queryKey;
    },
  };
}

// Helpers
const resolvePathParam = (key: string, pathParams: Record<string, string>) => {
  if (key.startsWith('{') && key.endsWith('}')) {
    return pathParams[key.slice(1, -1)];
  }
  return key;
};

const hasPathParams = (
  operation: QueryOperation
): operation is QueryOperation & {
  variables: { pathParams: Record<string, string> };
} => {
  return Boolean((operation.variables as any).pathParams);
};

const hasBody = (
  operation: QueryOperation
): operation is QueryOperation & {
  variables: { body: Record<string, unknown> };
} => {
  return Boolean((operation.variables as any).body);
};

const hasQueryParams = (
  operation: QueryOperation
): operation is QueryOperation & {
  variables: { queryParams: Record<string, unknown> };
} => {
  return Boolean((operation.variables as any).queryParams);
};
