import React, { Suspense } from 'react';
import AppLoading from '../pages/public/AppLoading';

const generateRoutes = (routes, RouteComponent) => {
  return routes.map(
    ({ children, validPerms, validRoles, path, component: Component }, index) =>
      children ? (
        // Route item with children
        children.map(
          ({ path, validPerms, validRoles, component: Component }, index) => (
            <RouteComponent
              key={index}
              path={path}
              validPerms={validPerms}
              validRoles={validRoles}
              exact
            >
              <Suspense fallback={<AppLoading />}>
                <Component />
              </Suspense>
            </RouteComponent>
          )
        )
      ) : (
        // Route item without children
        <RouteComponent
          key={index}
          path={path}
          validPerms={validPerms}
          validRoles={validRoles}
          exact
        >
          <Suspense fallback={<AppLoading />}>
            <Component />
          </Suspense>
        </RouteComponent>
      )
  );
};
export default generateRoutes;
