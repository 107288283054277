const typography = {
  useNextVariants: true,
  fontFamily: ['Inter', 'sans-serif'].join(','),
  fontStyle: 'normal',
  fontSize: 14,
  lineHeight: '22px',
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 700,
  h1: {
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: 32,
    lineHeight: '40px',
    letterSpacing: '-0.05em',
  },
  h2: {
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: 24,
    lineHeight: '30px',
    letterSpacing: '-0.03em',
  },
  h3: {
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: 16,
    lineHeight: '22px',
    letterSpacing: '-0.02em',
  },
  h4: {
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: 14,
    lineHeight: '18px',
    letterSpacing: '-0.02em',
  },
  h5: {
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: 12,
    lineHeight: '16px',
    letterSpacing: '-0.02em',
  },
  h6: {
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: 12,
    lineHeight: '16px',
    letterSpacing: '-0.02em',
  },
  body1: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '22px',
    letterSpacing: '-0.02em',
  },
  body2: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 12,
    lineHeight: '18px',
    letterSpacing: '-0.02em',
  },
  caption: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '15px',
    letterSpacing: '-0.02em',
  },
  button: {
    textTransform: 'none',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '15px',
    letterSpacing: '-0.02em',
  },
};

export default typography;
