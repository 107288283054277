import { useAuth0 } from '@auth0/auth0-react';
import { useSnackbar } from 'notistack';
import React, { ReactNode, useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  useProjectsFindingAreasList,
  useProjectsList,
  useProjectsRead,
} from '../services/one';
import {
  FindingArea,
  Project,
  ProjectDetail,
} from '../services/one/oneApiSchemas';

const defaultValues = {
  allProjects: [],
  projectsLoading: false,
  selectedProject: undefined,
  selectProject: undefined,
  reloadSelectedProject: () => undefined,
  reloadProjectsList: undefined,
  projectFindingAreas: [],
};

type TOneContext = {
  allProjects: Project[];
  projectsLoading: boolean;
  selectedProject: ProjectDetail | undefined;
  selectProject: ((project: ProjectDetail) => void) | undefined;
  reloadSelectedProject: () => void;
  reloadProjectsList: (() => void) | undefined;
  projectFindingAreas: FindingArea[];
};
export const OneContext = React.createContext<TOneContext>(defaultValues);

export const useOne = () => useContext(OneContext);

export const OneProvider = ({ children }: { children: ReactNode }) => {
  const { isAuthenticated } = useAuth0();
  const [selectedProjectId, setSelectedProjectId] = useState<
    string | undefined
  >(undefined);
  const storedProjectId = localStorage.getItem('selectedProjectId');

  const { projectIdent } = useParams<{ projectIdent: string }>();
  const history = useHistory();
  const snackbar = useSnackbar();

  const {
    data: allProjects,
    refetch: reloadProjectsList,
    isLoading: projectsLoading,
  } = useProjectsList({}, { initialData: [] });

  const [selectedProject, setSelectedProject] = useState<
    ProjectDetail | undefined
  >(undefined);
  const { data: projectData, refetch: doReloadSelectedProject } =
    useProjectsRead(
      {
        pathParams: {
          id: selectedProjectId || '',
        },
      },
      {
        enabled: !!selectedProjectId,
      }
    );
  useEffect(() => {
    if (projectData) {
      setSelectedProject(projectData);
      history.push(
        history.location.pathname.replace(
          selectedProject?.ident as string,
          projectData.ident
        )
      );
    }
  }, [projectData]);

  const { data: projectFindingAreas, refetch: doReloadProjectFindingAreas } =
    useProjectsFindingAreasList<FindingArea[]>(
      {
        pathParams: {
          id: selectedProjectId || '',
        },
      },
      { enabled: !!selectedProjectId }
    );

  const selectProject = (project: ProjectDetail) => {
    setSelectedProjectId(project?.id);
  };

  const reloadSelectedProject = () => {
    doReloadSelectedProject();
    doReloadProjectFindingAreas();
  };

  const setSelectedProjectByProjectIdent = () => {
    const newProject =
      allProjects?.find((item) => item.ident === projectIdent) || undefined;
    newProject
      ? setSelectedProjectId(newProject.id)
      : allProjects ??
        snackbar.enqueueSnackbar(
          `Project with ident "${projectIdent}" does not exist or you are missing project access privileges.`,
          { variant: 'error' }
        );
  };

  useEffect(() => {
    if (projectIdent) {
      setSelectedProjectByProjectIdent();
    } else {
      if (storedProjectId) {
        setSelectedProjectId(storedProjectId);
      } else {
        isAuthenticated && history.push('/projects');
      }
    }
  }, [projectIdent, allProjects, isAuthenticated]);

  useEffect(() => {
    selectedProjectId &&
      selectedProjectId !== storedProjectId &&
      localStorage.setItem('selectedProjectId', selectedProjectId);
  }, [selectedProjectId]);

  return (
    <OneContext.Provider
      value={{
        allProjects: allProjects as ProjectDetail[],
        projectsLoading,
        selectedProject,
        selectProject,
        reloadSelectedProject,
        reloadProjectsList,
        projectFindingAreas: (projectFindingAreas as FindingArea[]) ?? [],
      }}
    >
      {children}
    </OneContext.Provider>
  );
};
