import React from 'react';
import styled from 'styled-components';
import { useAuth0 } from '@auth0/auth0-react';
import { getConfig } from '../../config';

import {
  AppBar as MuiAppBar,
  Box,
  Grid,
  IconButton as MuiIconButton,
  Menu,
  MenuItem,
  Toolbar,
} from '@mui/material';

import { Menu as MenuIcon } from '@mui/icons-material';
import Avatar from '@mui/material/Avatar';
import ProjectSelection from './sidebar/ProjectSelection';

const AppBar = styled(MuiAppBar)`
  background: ${(props) => props.theme.header.background};
  color: ${(props) => props.theme.header.color};
  box-shadow: ${(props) => props.theme.shadows[1]};
`;

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

const UserMenu = () => {
  const { logout, user } = useAuth0();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <React.Fragment>
      <IconButton
        aria-owns={open ? 'menu-appbar' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        color="inherit"
        size="large"
      >
        <Avatar alt={user.name} src={user.picture} />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem disabled={true}>{user.name}</MenuItem>
        <MenuItem
          onClick={() => {
            logout({
              returnTo: getConfig('REACT_APP_AUTH0_LOGOUT_URL'),
            });
          }}
        >
          Sign out
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
};

const Header = ({ onDrawerToggle }) => {
  return (
    <AppBar position="sticky" elevation={0}>
      <Toolbar>
        <Grid container alignItems="center">
          <Grid item xs>
            <Box sx={{ display: { xs: 'block', md: 'none' } }}>
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={onDrawerToggle}
                size="large"
              >
                <MenuIcon />
              </IconButton>
            </Box>
          </Grid>
          <Grid item xs={8}>
            <Box display="flex" justifyContent="center">
              <ProjectSelection />
            </Box>
          </Grid>
          <Grid item xs>
            <Box textAlign="right">
              <UserMenu />
            </Box>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
